/**
 * Componentes DocumentUpload, AccountingUpload, XMLUpload, LinkUpload que permite llevar acabo todas las operaciones de carga de archivos
 */

import React from 'react';
import { useDispatch } from 'react-redux';
import { Upload, notification, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { upload, linking, accounting, xml } from '../../actions/storage/upload';

class FileUploadQueue {
  constructor() {
    this.queue = [];
    this.processing = false;
  }

  enqueue(file, callback) {
    this.queue.push({ file, callback });
    this.processNext();
  }

  async processNext() {
    if (this.processing || this.queue.length === 0) return;

    this.processing = true;
    const { file, callback } = this.queue.shift();

    try {
      await callback(file);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    this.processing = false;
    this.processNext();
  }
}

const fileUploadQueue = new FileUploadQueue();

/**
 * Exporta el componente DocumentUpload
 */
export const DocumentUpload = ({ reference, onAddFile }) => {
  const dispatch = useDispatch();

  const onChangeUpload = info => {
    switch (info.file.status) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se está cargando el archivo ${info.file.name}.`,
        });
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${info.file.name} fue cargado correctamente.`,
        });
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${info.file.name}.`,
        });
        break;
      default:
        break;
    }
  };

  const onUpload = file => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(upload(file.file, reference))
          .then(() => {
            file.onSuccess();
            onAddFile();
            resolve();
          })
          .catch(() => {
            file.onError();
            reject();
          });
      } catch (error) {
        file.onError();
        reject();
      }
    });
  };

  const handleCustomRequest = options => {
    fileUploadQueue.enqueue(options, onUpload);
  };

  return (
    <Upload
      name='file'
      multiple={true}
      customRequest={handleCustomRequest}
      showUploadList={false}
      onChange={onChangeUpload}
    >
      <Button type='dashed'>
        <UploadOutlined /> Subir archivos
      </Button>
    </Upload>
  );
};

/**
 * Exporta el componente AccountingUpload
 */
export const AccountingUpload = ({ reference, onAccounting }) => {
  const dispatch = useDispatch();

  const onChangeUpload = info => {
    switch (info.file.status) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se está cargando el archivo ${info.file.name}.`,
        });
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${info.file.name} fue cargado correctamente.`,
        });
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${info.file.name}.`,
        });
        break;
      default:
        break;
    }
  };

  const onUpload = file => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(accounting(file.file, reference))
          .then(() => {
            file.onSuccess();
            onAccounting();
            resolve();
          })
          .catch(() => {
            file.onError();
            reject();
          });
      } catch (error) {
        file.onError();
        reject();
      }
    });
  };

  const handleCustomRequest = options => {
    fileUploadQueue.enqueue(options, onUpload);
  };

  return (
    <Upload
      name='file'
      multiple={false}
      customRequest={handleCustomRequest}
      showUploadList={true}
      onChange={onChangeUpload}
    >
      <Button type='primary'>
        <UploadOutlined /> Subir contabilización
      </Button>
    </Upload>
  );
};

/**
 * Exporta el componente XMLUpload
 */
export const XMLUpload = ({ reference, onXML }) => {
  const dispatch = useDispatch();

  const onChangeUpload = info => {
    switch (info.file.status) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se está cargando el archivo ${info.file.name}.`,
        });
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${info.file.name} fue cargado correctamente.`,
        });
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${info.file.name}.`,
        });
        break;
      default:
        break;
    }
  };

  const onUpload = file => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(xml(file.file, reference))
          .then(() => {
            file.onSuccess();
            onXML();
            resolve();
          })
          .catch(() => {
            file.onError();
            reject();
          });
      } catch (error) {
        file.onError();
        reject();
      }
    });
  };

  const handleCustomRequest = options => {
    fileUploadQueue.enqueue(options, onUpload);
  };

  return (
    <Upload
      name='file'
      accept='text/xml'
      multiple={false}
      customRequest={handleCustomRequest}
      showUploadList={true}
      onChange={onChangeUpload}
    >
      <Button type='primary'>
        <UploadOutlined /> Subir archivo XML
      </Button>
    </Upload>
  );
};

/**
 * Exporta el componente LinkUpload
 */
export const LinkUpload = ({ reference, onLinking, onPreview, loading }) => {
  const dispatch = useDispatch();

  const onChangeUpload = info => {
    switch (info.file.status) {
      case 'uploading':
        notification.info({
          message: 'Cargando',
          description: `Se está cargando el archivo ${info.file.name}.`,
        });
        break;
      case 'done':
        notification.success({
          message: 'Cargado',
          description: `El archivo ${info.file.name} fue cargado correctamente.`,
        });
        break;
      case 'error':
        notification.error({
          message: 'Error',
          description: `Error al cargar el archivo ${info.file.name}.`,
        });
        break;
      default:
        break;
    }
  };

  const onUpload = file => {
    return new Promise((resolve, reject) => {
      try {
        onPreview();
        dispatch(linking(file.file, reference))
          .then(() => {
            file.onSuccess();
            onLinking();
            resolve();
          })
          .catch(() => {
            file.onError();
            reject();
          });
      } catch (error) {
        file.onError();
        reject();
      }
    });
  };

  const handleCustomRequest = options => {
    fileUploadQueue.enqueue(options, onUpload);
  };

  return (
    <Upload
      name='file'
      multiple={false}
      disabled={loading}
      customRequest={handleCustomRequest}
      showUploadList={false}
      onChange={onChangeUpload}
    >
      <Button type='primary' loading={loading} style={{ marginBottom: 16 }}>
        <UploadOutlined /> Vincular archivos
      </Button>
    </Upload>
  );
};
