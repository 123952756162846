/**
 * Componente Router que representa el enrutador de la aplicación
 */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { BrowserRouter, Route } from "react-router-dom";

import { AUTH } from '../../application/types';
import { roles, logout } from '../actions';
import {Home} from '../components/home';
import {Login} from '../components/login';

import {Loading} from '../components/loading';


export const Router = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const auth = useSelector(state => state.get('authenticate').get('login'));
  // Hook que permite utilizar las funciones de firebase
  const firebase = useFirebase();

  /**
   * Metodo que corresponde al ciclo de vida del componente
   */
  useEffect(() => {
    firebase.auth().onAuthStateChanged( async user => {
      if ( user ) {
        try {
          await dispatch( roles({ uid: user.uid }) );
          await dispatch({ type: AUTH.LOGGEDIN, user: user });
        } catch (error) { await dispatch({ type: AUTH.LOGGINGIN }); }
      } else dispatch({ type: AUTH.LOGGINGIN });
    })
  }, [ dispatch, firebase ]);

  useEffect(() => {
    if ( auth?.type === AUTH.LOGGEDIN ) {
      window.addEventListener("beforeunload", evt => {
        evt.preventDefault();
        dispatch( logout() );
      });
    }
  }, [ auth, dispatch ]);

  /**
   * Metodo que renderiza el componente
   *  
   */
  return (
    <BrowserRouter>
      <div>
      <Route 
          path = '/'
          render = { 
            () => auth 
              ? auth?.type === AUTH.LOGGEDIN 
                ? <Home /> 
                : <Login />
              : <Loading />
          }
        />
      </div>
    </BrowserRouter>
  );

};