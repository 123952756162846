import { DownloadOutlined } from "@ant-design/icons";
import JSZip from 'jszip';
import React, { useState } from 'react';
import { Button } from 'antd';
import { useFirestore } from 'react-redux-firebase';
import { useDocuments } from './context';

export default function DownloadFolder({ folder, handleFolderClick, allFolders }) {
    const [isDownloading, setIsDownloading] = useState(false);
    const { folders: contextFolders } = useDocuments(); // Contexto de react que tiene data de los subdocumentos y subcollections
    const customerRoot = contextFolders.routes.data[0].name; // Nombre de la raíz del cliente

    const firestore = useFirestore();

    // Función para agrupar archivos según su ubicación
    function groupFilesByLocation(files) {
        const root = {};
        files.forEach(file => {
            let current = root;
            const path = file.location;
            path.forEach((folder, index) => {
                if (!current[folder]) {
                    current[folder] = index === path.length - 1 ? [] : {};
                }

                if (index === path.length - 1) {
                    current[folder].push(file);
                } else {
                    current = current[folder];
                }
            });
        });

        return root;
    }

    // Función para obtener el nombre de la carpeta dado su ID
    function getFolderNameById(id, items) {
        const folder = items.find(f => f.id === id);
        return folder ? folder.name : null; // Si no se encuentra el nombre, se devuelve null
    }

    // Función para agregar archivos al ZIP
    async function addFilesToZip(zip, folderPath, files, items) {
        for (const key in files) {
            if (Array.isArray(files[key])) {
                for (const file of files[key]) {
                    const response = await fetch(file.urlDescarga);
                    const pdfBlob = await response.blob();
                    const fileName = file.name;
                    zip.folder(folderPath).file(fileName, pdfBlob);
                }
            } else {
                const translatedFolderName = getFolderNameById(key, items);
                if (translatedFolderName) {
                    const newFolderPath = folderPath ? `${folderPath}/${translatedFolderName}` : translatedFolderName;
                    await addFilesToZip(zip, newFolderPath, files[key], items);
                } else {
                    await addFilesToZip(zip, folderPath, files[key], items); // Omite el nivel de directorio
                }
            }
        }
    }

    // Función para descargar la carpeta
    const downloadFolder = async () => {
        try {
            setIsDownloading(true);
            const items = await handleFolderClick(folder.reference.path.collection(folder.reference.id));
             // Asegúrate de que items tiene la estructura esperada

            // Obtener referencia de la carpeta 'files' en Firestore
            const folderRef = firestore.collection('files');
            const folderSnapshot = await folderRef.get();

            // Array para almacenar todos los archivos
            const allFiles = [];
            const folderIds = new Set();

            // Contador de archivos totales
            let totalFiles = 0;

            // Iterar sobre los documentos en la carpeta 'files'
            folderSnapshot.forEach(doc => {
                if (totalFiles < 10) { // Limitar a 1000 archivos en total
                    const data = doc.data();
                    const customer = data.customer;

                    if (customer === customerRoot) {
                        const fullPath = data.folder.f_.path.segments;
                        fullPath.slice(2).forEach(segment => folderIds.add(segment));

                        const url = fullPath.join('/');
                        const folderPath = extractFolderPathFromFileURL(url);

                        if (folderPath) {
                            allFiles.push({
                                name: data.name,
                                url: url,
                                urlDescarga: data.url,
                                location: fullPath.slice(2) // Ajustar para comenzar desde "folders"
                            });
                        } else {
                            console.error("No se pudo extraer la carpeta para el archivo:", data.name);
                        }
                    }
                }
            });

            // Agrupar archivos por ubicación
            const groupedFiles = groupFilesByLocation(allFiles);

            // Crear un archivo ZIP para almacenar todos los archivos
            const zip = new JSZip();

            // Agregar los archivos al archivo ZIP
            await addFilesToZip(zip, "", groupedFiles, items);

            // Generar el archivo ZIP
            const zipBlob = await zip.generateAsync({ type: "blob" });

            // Crear un enlace de descarga para el archivo ZIP
            const zipUrl = URL.createObjectURL(zipBlob);
            const link = document.createElement("a");
            link.href = zipUrl;
            link.download = customerRoot;
            document.body.appendChild(link);
            link.click();

            // Limpiar el enlace y la URL después de la descarga
            document.body.removeChild(link);
            URL.revokeObjectURL(zipUrl);
        } catch (error) {
            console.error("Error al descargar la carpeta:", error);
        } finally {
            setIsDownloading(false);
        }
    }

    // Función para extraer la carpeta de la URL del archivo
    function extractFolderPathFromFileURL(path) {
        const startIndex = path.indexOf("folders/") + "folders/".length;
        const nextSlashIndex = path.indexOf("/", startIndex);
        const folderPath = path.substring(startIndex, nextSlashIndex);
        return folderPath;
    }

    return (
        <Button type="link" style={{ paddingLeft: 0 }} onClick={downloadFolder}>
            <DownloadOutlined style={{ marginRight: 8 }} />
            {isDownloading ? 'Descargando' : 'Descargar carpeta'}
        </Button>
    );
}
