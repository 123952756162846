/**
 * Exporta los reductores de la aplicación
 */

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { combineReducers } from 'redux-immutable';
import { authenticate } from './login';
import { documents } from './documents';
import { customers } from './customers';
import { users } from './users';
import { roles } from './roles';
import { upload } from './upload';
import { references } from './references';
import { folders } from './folders';

export const reducers = combineReducers({
  authenticate: authenticate,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  documents: documents,
  customers: customers,
  users: users,
  roles: roles,
  upload: upload,
  references: references,
  folders: folders
});

export default reducers;