/**
 * Acción de REDUX que permite terminar la sesión de un usuario 
 * No recibe parametros
 */

import { AUTH } from '../../../application/types';

/**
 * Exporta la función de logout
 */
export const logout = () => ( dispatch, state, firebase ) => {
  return firebase().auth().signOut()
    .then( 
      () => dispatch({
        type: AUTH.LOGGINGOUT
      })
    )
    .catch( 
      error => dispatch({ 
        type: AUTH.ERROR,
        code: error.code,
        message: error.message
      })
    )
}