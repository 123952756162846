/**
 * Acción de REDUX que permite llevar a cabo todas las operaciones sobre los clientes 
 */

import { CUSTOMERS, DOCUMENTS } from '../../../application/types';

/**
 * Exporta las funciones para el manejo de los clientes
 */
export const customers = {

  /**
   * Función que permite agregar usuario y vincularlos a un cliente.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * ID del cliente
   * Email del usuario que se va a vincular
   */
  addUser: ({ roles, id, email }) => ( dispatch, state, firebase ) => {
    
    dispatch({ type: CUSTOMERS.CREATE.CREATING_CUSTOMERS });

    return new Promise( ( resolve, reject ) => {
      if ( roles.create ) {
      
        const db = firebase().firestore();
        const user = db.collection('users').where( 'Email', '==', email );

        user.get().then( query => {
          if ( query.size > 0 ) {

            const userRef = query.docs[0].ref;
            const customer = db.collection('customers').doc( id );

            customer.get().then( query => {
              if (!(query.data()?.['Usuarios']?.filter(user => user.email === email).length > 0)) {
                const uid = firebase().auth().currentUser.uid;
                const batch = db.batch();

                const log_audit = db.collection('audit').doc();
                const users = query.data()?.['Usuarios'] || [];

                users.push({ email: email, reference: userRef })

                const before = { ...query.data() };
                const after = { ...query.data(), 'Usuarios': users };
                
                batch.set(log_audit, { uid, eventType: CUSTOMERS.UPDATE.UPDATE_CUSTOMERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
                batch.set(log_audit.collection('before').doc(), before);
                batch.set(log_audit.collection('after').doc(), after);
                batch.update(customer, {
                  'Usuarios': firebase().firestore.FieldValue.arrayUnion({
                    email: email,
                    reference: userRef
                  })
                });

                return batch.commit().then(() => {
                  resolve(
                    dispatch({ type: CUSTOMERS.UPDATE.UPDATED_CUSTOMERS, data: userRef })
                  )
                }).catch(
                  error => reject(
                    dispatch({ type: CUSTOMERS.UPDATE.ERROR, code: error.code, message: error.message })
                  )
                );
              } resolve();
            }).catch(
              error => reject(
                dispatch({ type: CUSTOMERS.CREATE.ERROR, code: error.code, message: error.message })
              )
            )

          } else {
            reject({ code: 'data', message: 'El usuario no esta registrado' });
          }
        }).catch(
          error => reject(
            dispatch({ type: CUSTOMERS.CREATE.ERROR, code: error.code, message: error.message })
          )
        )
        
      }  else {
        reject(
          dispatch({ type: CUSTOMERS.CREATE.ERROR, code: 'permissions', message: 'Permisos insuficientes para leer la lista de usuarios' })
        );
      }
    })

  },

  /**
   * Función que permite crear un cliente en el sistema.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * NIT del cliente que se va a crear
   * Razon social del cliente que se va a crear
   */
  create: ({ roles, nit, name }) => ( dispatch, state, firebase ) => {

    dispatch({ type: CUSTOMERS.CREATE.CREATING_CUSTOMERS });
  
    return new Promise( ( resolve, reject ) => {
      if ( roles.create ) {
  
        const db = firebase().firestore();
        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();

        const log_audit = db.collection('audit').doc();
        const customer_ref = db.collection('customers').doc();

        const after = { "NIT": nit, "Razon social": name }

        batch.set(log_audit, { uid, eventType: CUSTOMERS.CREATE.CREATE_CUSTOMERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('after').doc(), after);
        batch.set(customer_ref, after);

        batch.commit().then(() => {
          resolve(
            dispatch({ type: CUSTOMERS.CREATE.CREATED_CUSTOMERS, data: customer_ref })
          )
        }).catch(
          error => reject(
            dispatch({ type: CUSTOMERS.CREATE.ERROR, code: error.code, message: error.message })
          )
        );
    
      } else {
        reject(
          dispatch({ type: CUSTOMERS.CREATE.ERROR, code: 0, message: 'Permisos insuficientes para crear clientes' })
        );
      }
    })

  },
  
  /**
   * Función que permite consultar la lista de clientes.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * El filtro que se desea aplicar a la consulta
   */
  read: ({ roles, filter }) => ( dispatch, state, firebase ) => {
    
    dispatch({ type: CUSTOMERS.READ.READING_CUSTOMERS });
    
    return new Promise( ( resolve, reject ) => {
      if ( roles.read ) {

        filter = filter ? filter.value.trim().length > 0 ? filter : undefined : undefined

        const db = firebase().firestore();
        const customers = db.collection('customers');
        const documents = filter ? customers.where( filter.field, '==', filter.value ) : customers

        documents.get()
          .then( query => {

              const res = query.docs.map( doc => {

                const data = doc.data();
                const roles = state().get('roles').get('roles').roles.documents;
                
                const res = roles.level === 'all'
                  ? { id: doc.id, data: data }
                  : Array.isArray( data['Usuarios'] )
                    ? data['Usuarios'].filter( usr => usr.reference.id === firebase().auth().currentUser.uid ).length > 0
                      ? { id: doc.id, data: data }
                      : {}
                    : {}
                
                if ( Object.getOwnPropertyNames(res).length > 0 ) return res; else return doc;

              });

              resolve( dispatch({ type: CUSTOMERS.READ.READED_CUSTOMERS, customers: res.filter( item => item !== undefined ) }) )

          }).catch( error => reject(
            dispatch({ type: CUSTOMERS.READ.ERROR, code: error.code, message: error.message })
          ))
    
      } else {
        reject(
          dispatch({ type: CUSTOMERS.READ.ERROR, code: 0, message: 'Permisos insuficientes para consultar la lista de clientes' })
        )
      }
    })
    
  },
  
  /**
   * Función que permite actualizar la información de un cliente.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Los nuevos datos del cliente
   */
  update: ({ roles, data }) => ( dispatch, state, firebase ) => {
  
    dispatch({ type: CUSTOMERS.UPDATE.UPDATING_CUSTOMERS });
  
    return new Promise( async ( resolve, reject ) => {
      if ( roles.update ) {
    
        const db = firebase().firestore();
        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();

        const log_audit = db.collection('audit').doc();
        const customer_ref = db.collection('customers').doc(data.id);

        const before = (await customer_ref.get()).data();
        const after = { ...before, ...data.fields }

        batch.set(log_audit, { uid, eventType: CUSTOMERS.UPDATE.UPDATE_CUSTOMERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('before').doc(), before);
        batch.set(log_audit.collection('after').doc(), after);
        batch.update(customer_ref, { ...data.fields });

        return batch.commit().then(() => {
          resolve(
            dispatch({ type: CUSTOMERS.UPDATE.UPDATED_CUSTOMERS, data: { id: data.id } })
          )
        }).catch(
          error => reject(
            dispatch({ type: CUSTOMERS.UPDATE.ERROR, code: error.code, message: error.message })
          )
        );

      } else {
        reject(
          dispatch({ type: CUSTOMERS.UPDATE.ERROR, code: 0, message: 'Permisos insuficientes para actualizar la lista de clientes' })
        )
      }
    });
    
  },
  
  /**
   * Función que permite desvincular un usuario de la lista de clientes.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * ID del cliente
   * Email del usuario que se va a desvincular
   */
  removeUser: ({ roles, id, email }) => ( dispatch, state, firebase ) => {
    
    dispatch({ type: CUSTOMERS.DELETE.DELETING_CUSTOMERS });

    return new Promise( async ( resolve, reject ) => {
      if ( roles.delete ) {
      
        const db = firebase().firestore();
        const customer = db.collection('customers').doc( id );

        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();
        const log_audit = db.collection('audit').doc();

        const before = (await customer.get()).data();

        const data = Object.assign([], before?.['Usuarios']);
        const user = data?.filter( user => user.email === email )[0];

        data.splice(data.indexOf(user), 1);
        const after = { ...before, 'Usuarios': data };

        batch.set(log_audit, { uid, eventType: CUSTOMERS.UPDATE.UPDATE_CUSTOMERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('before').doc(), before);
        batch.set(log_audit.collection('after').doc(), after);
        batch.update(customer, { 'Usuarios': firebase().firestore.FieldValue.arrayRemove(user) });

        return batch.commit().then(() => {
          resolve(
            dispatch({ type: CUSTOMERS.UPDATE.UPDATED_CUSTOMERS, data: { id } })
          )
        }).catch(
          error => reject(
            dispatch({ type: CUSTOMERS.UPDATE.ERROR, code: error.code, message: error.message })
          )
        );

      }
    })

  },

  /**
   * Función que permite asignar a un usuario vinculado a un cliente como administrador.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * ID del cliente
   * Información del cliente
   */
  setUserAdmin: ({ roles, id, item }) => ( dispatch, state, firebase ) => {

    dispatch({ type: CUSTOMERS.UPDATE.UPDATING_CUSTOMERS });

    return new Promise( ( resolve, reject ) => {
      if ( roles.update ) {
      
        const db = firebase().firestore();
        const customer = db.collection('customers').doc( id );

        customer.get().then( async doc => {

          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();
          const log_audit_customers = db.collection('audit').doc();
          const log_audit_permissions = db.collection('audit').doc();

          const before = doc.data();

          let users = Object.assign([], doc.data()?.['Usuarios']);
          const index = users.findIndex( user => user.email === item.email );

          users[ index ].isAdmin = !item.isAdmin
          const after = { ...before, 'Usuarios': users };

          const before_permissions = (await db.doc(`folders/${ id }/users/${ item.reference.id }`).get()).data() || null;
          const after_permissions = {
            user: item.reference,
            permissions: {
              documents: {
                accounting: users[ index ].isAdmin,
                create: users[ index ].isAdmin,
                delete: users[ index ].isAdmin,
                read: users[ index ].isAdmin,
                update: users[ index ].isAdmin
              },
              folders: {
                closing: users[ index ].isAdmin,
                create: users[ index ].isAdmin,
                delete: users[ index ].isAdmin,
                read: users[ index ].isAdmin,
                update: users[ index ].isAdmin
              }
            }
          };

          batch.set(log_audit_customers, { uid, eventType: CUSTOMERS.UPDATE.UPDATE_CUSTOMERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_customers.collection('before').doc(), before);
          batch.set(log_audit_customers.collection('after').doc(), after);
          
          batch.set(log_audit_permissions, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_PERMISSIONS`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          if (before_permissions) batch.set(log_audit_permissions.collection('before').doc(), before_permissions);
          batch.set(log_audit_permissions.collection('after').doc(), after_permissions);

          batch.update(customer, { 'Usuarios': users });
          batch.set(db.doc(`folders/${ id }/users/${ item.reference.id }`), after_permissions);

          return batch.commit().then(() => {
            resolve(
              dispatch({ type: CUSTOMERS.UPDATE.UPDATED_CUSTOMERS, data: { id } })
            )
          }).catch(
            error => reject(
              dispatch({ type: CUSTOMERS.UPDATE.ERROR, code: error.code, message: error.message })
            )
          );

        }).catch( error => reject(
          dispatch({ type: CUSTOMERS.UPDATE.ERROR, code: error.code, message: error.message })
        ))
      
      }
    })

  },

  /**
   * Función que permite eliminar un cliente de la lista de clientes.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * ID del cliente
   */
  delete: ({ roles, id }) => ( dispatch, state, firebase ) => {
  
    dispatch({ type: CUSTOMERS.DELETE.DELETING_CUSTOMERS });
  
    return new Promise( async ( resolve, reject ) => {
      if ( roles.delete ) {

        const db = firebase().firestore();
        const uid = firebase().auth().currentUser.uid;
        const batch = db.batch();

        const log_audit = db.collection('audit').doc();
        const customer_ref = db.collection('customers').doc(id);
        const before = (await customer_ref.get()).data();

        batch.set(log_audit, { uid, eventType: CUSTOMERS.DELETE.DELETE_CUSTOMERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
        batch.set(log_audit.collection('before').doc(), before);
        batch.delete(customer_ref);
        batch.commit().then(() => {
          resolve(
            dispatch({ type: CUSTOMERS.DELETE.DELETED_CUSTOMERS, data: { id } })
          )
        }).catch(
          error => reject(
            dispatch({ type: CUSTOMERS.DELETE.ERROR, code: error.code, message: error.message })
          )
        );

      } else {
        reject(
          dispatch({ type: CUSTOMERS.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar la lista de clientes' })
        )
      }
    })

  }

}