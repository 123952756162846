import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Drawer, Input, Checkbox, notification } from 'antd';
import { FolderOutlined  } from '@ant-design/icons';

import { documents as documentsAction } from '../../actions/database/documents';
import { folders as foldersAction } from '../../actions/database/folders';

export const DrawerFolder = ({
    docsFolders,
    setDocsFolders,
    setDrawerFolder,
    drawerFolder,
    drawer,
    row,
    setRow,
    roles,
    setFolders,
    loadFolders,
    folderFields,
    templatesFields }) => {

    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();
    // Hook para el control de los formularios
    const [form] = Form.useForm();

    /**
     * Metodo que se ejecuta cuando se cierra la venta emergente de carpetas
     */
    const closeDrawerFolder = () => {
        setDrawerFolder({ isupdate: false, visible: false, loading: false });
        setRow({ key: [], data: [] });
    };

    /**
     * Metodo que permite guardar una carpeta
     */
    const saveFolder = values => {
        setDrawerFolder({ isupdate: drawer.isupdate, visible: true, loading: true });
        dispatch( foldersAction.templates.create({
            roles: roles.folders.templates,
            template: {
                name: templatesFields.name,
                description: templatesFields.description
            }
        })).then( data => {
            dispatch( documentsAction.create({
                reference: row.data.length > 0 ? row.data[0].reference : data.template.doc('documents'),
                roles: roles.folders.templates,
                folder: {
                    name: values.name,
                    description: values.description,
                    isUpload: values.isUpload
                }
            })).then( () => {
                setDrawerFolder({ isupdate: false, visible: false, loading: false });
                setFolders({ loading: true, source: [] });
                setRow({ key: [], data: [] });
                setDocsFolders(data.template.doc('documents'));
                loadFolders({ doc: data.template.doc('documents'), folders: [] }).then( source => setFolders({ loading: false, source }) ).catch( error => {
                    notification.error({
                        message: 'Error',
                        description: "Se presento un error al tratar de cargar las carpetas",
                        placement: 'topLeft'
                    });
                });
            }).catch( error => {
                notification.error({
                    message: 'Error',
                    description: "Se presento un error al tratar de crear la carpeta",
                    placement: 'topLeft'
                });
            });
        }).catch( error => {
            notification.error({
                message: 'Error',
                description: "Se presento un error al tratar de crear la plantilla",
                placement: 'topLeft'
            });
        });
    };

    /**
     * Metodo que permite actualizar una carpeta de la plantilla
     */
    const updateFolder = values => {
        setDrawerFolder({ isupdate: drawer.isupdate, visible: true, loading: true });
        setFolders({ visible: true, loading: true });
        dispatch( documentsAction.update({
            item: {
                name: row?.data[0]?.name,
                reference: {
                    path: row?.data[0]?.reference?.parent,
                    id: row?.data[0]?.reference?.id
                }
            },
            roles: roles.folders.templates,
            values: {
                name: values.name,
                description: values.description,
                isUpload: values.isUpload
            }
        })).then( () => {
            setDrawerFolder({ isupdate: false, visible: false, loading: false });
            setFolders({ loading: true, source: [] });
            setRow({ key: [], data: [] });
            loadFolders({ doc: docsFolders, folders: [] }).then( source => setFolders({ loading: false, source }) ).catch( error => {
                notification.error({
                message: 'Error',
                description: "Se presento un error al tratar de cargar las carpetas",
                placement: 'topLeft'
                });
            });
        });
    };

    useEffect(() => {
        form.setFields([
            { name: "name", value: folderFields.name },
            { name: "description", value: folderFields.description },
            { name: "isUpload", value: folderFields.isUpload }
        ]);
    });

    return (
        <Drawer
            title = { `${ drawerFolder.isupdate ? "Editar" : "Nueva" } Carpeta` }
            width = { 320 }
            placement = "left"
            onClose = { closeDrawerFolder }
            maskClosable = { false }
            visible = { drawerFolder.visible }
            style = {{ height: 'calc(100% - 55px)', overflow: 'auto', paddingBottom: 53 }}
            destroyOnClose = { true } >
            
            <Form form = { form } layout = "vertical" onFinish = { drawerFolder.isupdate ? updateFolder : saveFolder } >
                <Form.Item name = "name" label = "Carpeta" rules = {[{ required: true, message: 'El nombre de la carpeta es requerido' }]} >
                    <Input placeholder = "Nombre de la carpeta" prefix = { <FolderOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } />
                </Form.Item>
                <Form.Item name = "description" label = "Descripcion" rules = {[{ required: true, message: 'La descripcion de la carpeta es requerida' }]} >
                    <Input.TextArea placeholder = "Descripcion de la carpeta" rows = { 4 } />
                </Form.Item>
                <Form.Item name = "isUpload" valuePropName = "checked" initialValue = { false } >
                    <Checkbox> Permitir subir archivos? </Checkbox>
                </Form.Item>
            </Form>

            <div style = {{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
            }} >
                <Button style = {{ marginRight: 8 }} loading = { drawerFolder.loading } onClick = { closeDrawerFolder } > Cancelar </Button>
                <Button type = "primary" htmlType = "submit" onClick = { () => form.submit() } loading = { drawerFolder.loading } > Guardar </Button>
            </div>

        </Drawer>
    )
};