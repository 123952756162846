/**
 * Acción de REDUX que permite obtener los roles de un usuario
 */

import { ROLES } from '../../../application/types';

/**
 * Exporta la función de roles
 * Recibe como parametros:
 * El ID del usuario
 */
export const roles = ({ uid }) => ( dispatch, state, firebase ) => {
  
  dispatch({ type: ROLES.GETTING_ROLES });

  const db = firebase().firestore();
  const role = db.collection('users').doc( uid );

  return new Promise( ( resolve, reject ) => {
    role.get()
      .then( query => {
        query.data()['Rol'].get()
          .then( data => {
            resolve(
              dispatch({
                type: ROLES.GETTED_ROLES,
                roles: data.data()
              })
            );    
          })
          .catch( error => {
            reject(
              dispatch({
                type: ROLES.ERROR,
                code: error.code,
                message: error.message
              })
            );
          })
      })
      .catch( error => {
        reject(
          dispatch({
            type: ROLES.ERROR,
            code: error.code,
            message: error.message
          })
        );
      })

  })
  
}