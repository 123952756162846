/**
 * Reductor de REDUX para controlar los estados del componente de referencias
 */

import { REFERENCES } from '../../application/types';
import { Map } from 'immutable';

/**
 * Exporta el reductor references
 * @param {object} state 
 * @param {object} action 
 */
export const references = ( state = Map({ type: REFERENCES.READ.READ_REFERENCE }), action ) => {

  switch ( action.type ) {

    case REFERENCES.CREATE.CREATE_REFERENCE:
      return state.set('create', {
        type: action.type
      });
    
    case REFERENCES.CREATE.CREATING_REFERENCE:
      return state.set('create', {
        type: action.type,
      });
  
    case REFERENCES.CREATE.CREATED_REFERENCE:
      return state.set('create', {
        type: action.type,
        reference: action.reference
      });

    case REFERENCES.CREATE.ERROR:
      return state.set('create', {
        type: action.type,
        code: action.code,
        message: action.message
      });
    
    case REFERENCES.READ.READ_REFERENCE:
      return state.set('read', {
        type: action.type
      });
    
    case REFERENCES.READ.READING_REFERENCE:
      return state.set('read', {
        type: action.type,
      });
  
    case REFERENCES.READ.READED_REFERENCE:
      return state.set('read', {
        type: action.type,
        reference: action.reference
      });

    case REFERENCES.READ.ERROR:
      return state.set('read', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case REFERENCES.UPDATE.UPDATE_REFERENCE:
      return state.set('update', {
        type: action.type
      });
    
    case REFERENCES.UPDATE.UPDATING_REFERENCE:
      return state.set('update', {
        type: action.type,
      });
  
    case REFERENCES.UPDATE.UPDATED_REFERENCE:
      return state.set('update', {
        type: action.type,
        reference: action.reference
      });

    case REFERENCES.UPDATE.ERROR:
      return state.set('update', {
        type: action.type,
        code: action.code,
        message: action.message
      });

      case REFERENCES.DELETE.DELETE_REFERENCE:
      return state.set('delete', {
        type: action.type
      });
    
    case REFERENCES.DELETE.DELETING_REFERENCE:
      return state.set('delete', {
        type: action.type,
      });
  
    case REFERENCES.DELETE.DELETED_REFERENCE:
      return state.set('delete', {
        type: action.type,
        reference: action.reference
      });

    case REFERENCES.DELETE.ERROR:
      return state.set('delete', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}