/**
 * Importación de las dependencias del proyecto
 */
import React from 'react';

import { EventFields } from './event_fields';
import { EventTable } from './event_table';
import AuditProvider from './context';

/**
 * Componente de función que incializa los elementos del Log de auditoria
 * @returns {React.ReactNode}
 */
export const Audit = () => (
    <AuditProvider >
  
            <EventFields />
            <EventTable />
 
    </AuditProvider>
);