import React, { useRef, useState, useEffect } from 'react';
import { firestore } from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import { Button, Tag } from 'antd';
import { FolderFilled, FileFilled } from '@ant-design/icons';

// Función para obtener los datos de una carpeta y sus subcarpetas recursivamente
export const loadAllFoldersRecursively = async (folderRef) => {
    const items = [];
    console.log("items, folder ref", folderRef);

    // Función recursiva para cargar carpetas y archivos
    const loadFolders = async (reference, path = '') => {
        const folderSnapshot = await reference.get();
        console.log("folderSnapshot", folderSnapshot);

        if (folderSnapshot.docs) { // Es una colección
            for (const doc of folderSnapshot.docs) {
                const docData = doc.data();

                if (Array.isArray(docData.folders)) {
                    for (const folder of docData.folders) {
                        console.log("folder data ", folder)
                        const folderPath = path ? `${path}/${folder.name}` : folder.name;
                        console.log(folder)
                        items.push({
                            name: folder.name,
                            id: folder.reference.id
                        });

                        // Llamada recursiva para cargar subcarpetas
                        await loadFolders(doc.ref.collection(folder.reference.id), folderPath);
                    }
                }

                if (Array.isArray(docData.files)) {
                    for (const file of docData.files) {
                        const filePath = path ? `${path}/${file.name}` : file.name;
                        items.push({
                            key: uuidv4(),
                            sorted: filePath,
                            avatar: <FileFilled />,
                            description: (
                                <>
                                    <p>{file.description}</p>
                                </>
                            ),
                            actions: [], // Puedes agregar las acciones necesarias
                            title: (
                                <Tag color="#f50">{filePath}</Tag>
                            )
                        });
                    }
                }
            }
        } else if (folderSnapshot.exists) { // Es un documento
            const folderData = folderSnapshot.data();

            if (Array.isArray(folderData.folders)) {
                for (const folder of folderData.folders) {
                    const folderPath = path ? `${path}/${folder.name}` : folder.name;
                    items.push({
                        key: uuidv4(),
                        sorted: folderPath,
                        avatar: <FolderFilled />,
                        description: (
                            <>
                                <p>{folder.description}</p>
                                {folder?.close?.date ? (
                                    <Tag color='green'>
                                        Fecha de cierre: {
                                            folder.close.date?.toDate().toLocaleDateString('es-CO', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })
                                        }
                                    </Tag>
                                ) : null}
                            </>
                        ),
                        actions: [], // Puedes agregar las acciones necesarias
                        title: (
                            <Tag color="#f50">{folderPath}</Tag>
                        )
                    });

                    // Llamada recursiva para cargar subcarpetas
                    await loadFolders(reference.collection(folder.reference.id), folderPath);
                }
            }

            if (Array.isArray(folderData.files)) {
                for (const file of folderData.files) {
                    const filePath = path ? `${path}/${file.name}` : file.name;
                    items.push({
                        key: uuidv4(),
                        sorted: filePath,
                        avatar: <FileFilled />,
                        description: (
                            <>
                                <p>{file.description}</p>
                            </>
                        ),
                        actions: [], // Puedes agregar las acciones necesarias
                        title: (
                            <Tag color="#f50">{filePath}</Tag>
                        )
                    });
                }
            }
        }
    };

    await loadFolders(folderRef);
    console.log("items, folder ref", items);
    return items;
};
