/**
 * Componente Fields que permite renderizar el formulario y campos del buscador
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Row, TreeSelect, notification, Input, DatePicker, Select, InputNumber } from 'antd';
import moment from 'moment';

import { 
  references as referencesAction
} from '../../actions/database/references';
import { 
  customers as customersAction
} from '../../actions/database/customers';
import { 
  users as usersAction 
} from '../../actions/database/users';
import { REFERENCES, USERS } from '../../../application/types';
import { useDocuments } from '../documents/context';


/**
 * Exporta el componente Fields
 */
export const useFields = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const auth = useSelector(state => state.get('authenticate').get('login'));

  // Manejo de los estados del componente
  const [isLoad, setIsLoad] = useState(true);

  /**
   * Metodo que permite cargar la lista de clientes y asignarlos en el campo correspondiente
   */
  const loadCustomers = () => {
    return new Promise( ( resolve, reject ) => {
      dispatch( customersAction.read({ roles: roles.documents }) ).then(({ customers }) => {
        
        const source = [{ key: -1, title: 'Todos', value: "-1", children: [] }];
        // eslint-disable-next-line no-unused-expressions
        customers?.forEach( ( customer, index ) => {

          const isUsers = Array.isArray( customer.data['Usuarios'] );
          const user = isUsers ? customer.data['Usuarios'].filter( user => user.reference.id === auth.user.uid ) : [];

          if ( roles.documents.level === "all" || user.length > 0 ) {
            source[0].children.push({
              key: `${ customer.data['NIT'] }/${ customer.data['Razon social'] }`,
              title: customer.data['Razon social'],
              value: `${ customer.data['NIT'] }/${ customer.data['Razon social'] }`
            });
          }
        
        }); resolve( source );

      }).catch( error => reject( error) );
    });
  };

  /**
   * Metodo que permite cargar la lista de referencias y asignarlos en el campo correspondiente
   */
  const loadReferences = () => {
    return new Promise( ( resolve, reject ) => {
      dispatch(
        referencesAction.read({
          roles: roles.references
        })
      ).then( () => resolve() ).catch( error => reject( error ) );
    });
  };

  /**
   * Metodo que permite la carga inicial
   */
  const load = () => new Promise((resolve, reject) => {
    if (isLoad) {
      setIsLoad(false);
      dispatch( usersAction.read({ roles: { read: true } }) ).then( () => {
        loadCustomers().then( source => {
          loadReferences().catch( error => reject("Se presento un error al tratar de cargar la lista de clientes") );
          resolve(source);
        }).catch( error => reject("Se presento un error al tratar de cargar la lista de clientes") );
      }).catch( error => reject("Se presento un error al tratar de cargar la lista de usuarios") );
    }
  });

  return {
    fields: { load, isLoad }
  };
};

export const Fields = ({ expand, form }) => {

  // Selecciona los reductores
  const users = useSelector(state => state.get('users').get('read'));
  const references = useSelector(state => state.get('references').get('read'));
  
  // Manejo de los estados del componente
  const [treeData, setTreeData] = useState({ value: [], source: [], loading: false });
  const [selected, setSelected] = useState({ component: <Input style = {{ width: '100%' }} /> });

  const { fields } = useDocuments();

  /**
   * Metodo que se ejecuta al cambiar de referencia, carga el campo de valor segun el tipo de referencia
   * @param {string} value 
   */
  const handleSelectChangeReference = value => {
    const ref = references.reference.filter( ref => ref.name === value )[0];
    switch ( ref.type ) {
      case "Numérico":
        setSelected({ component: <InputNumber style = {{ width: '100%' }} /> });
        form.setFieldsValue({ referencevalue: 0 });
        break;
      case "Alfanumérico":
        setSelected({ component: <Input style = {{ width: '100%' }} /> })
        form.setFieldsValue({ referencevalue: "" });
        break;
      case "Fecha":
        setSelected({ component: <DatePicker style = {{ width: '100%' }} /> });
        form.setFieldsValue({ referencevalue: moment() });
        break;
      default:
        break;
    }
  };

  // Funcion que construye los nodos de seleccion de carpeta
  const buildTreeNode = useCallback(nodes => nodes?.map(({ children, ...props }) => children?.length > 0
    ? <TreeSelect.TreeNode { ...props } > { buildTreeNode(children) } </TreeSelect.TreeNode>
    : <TreeSelect.TreeNode { ...props } />
  ), []);

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => {
    fields.load().then(source => setTreeData({
      value: [],
      source: buildTreeNode(source),
      loading: false
    }) ).catch( error => {
      notification.error({
        message: 'Error',
        description: error,
        placement: 'topLeft'
      });
    });
  }, [ fields, buildTreeNode ]);

  /**
   * Metodo que renderiza el componente
   */
  return (
    <Row gutter = { 8 } >

      <Col span = { 24 } >
        <Form.Item label = 'Cliente' name = "customer" style = {{ display: 'block' }}
          rules = {[{ required: true, message: 'Debe seleccionar una empresa' }]} >
          <TreeSelect showSearch allowClear treeDefaultExpandAll
              maxTagCount = { 3 }
              dropdownStyle = {{ maxHeight: 400, overflow: 'auto' }}
              disabled = { treeData.loading }
              onChange = { value => setTreeData({ value, ...treeData })}
              treeCheckable = { true }
              showCheckedStrategy = { TreeSelect.SHOW_PARENT }
              placeholder = 'Seleccione la empresa'
            > { treeData.source } </TreeSelect>
        </Form.Item>
      </Col>

      <Col span = { 14 } >
        <Form.Item label = 'Referencia' name = "reference" style = {{ display: expand ? 'block' : 'none' }} >
          <Select placeholder = "Seleccione una referencia" onChange = { handleSelectChangeReference } >
            { references?.type === REFERENCES.READ.READED_REFERENCE
                ? references?.reference?.map( ref => (
                    <Select.Option key = { ref.id } value = { ref.name } >
                      { ref.name }
                    </Select.Option>
                  )) : [] }
          </Select>
        </Form.Item>
      </Col>

      <Col span = { 10 } >
        <Form.Item label = 'Valor de referencia' name = "referencevalue" style = {{ display: expand ? 'block' : 'none' }} >
          { selected.component }
        </Form.Item>
      </Col>

      <Col span = { 18 } style = {{ display: expand ? 'block' : 'none' }} >
        <Form.Item label = 'Fecha de subida' name = "date_of_upload" >
          <DatePicker.RangePicker
            onChange = { ( date, dateString ) => form.setFields([{ name: "uploadyearmonth", value: dateString }]) }
            style = {{ width: '100%' }}
          />
        </Form.Item>
      </Col>


      <Col span = { 6 } style = {{ display: expand ? 'block' : 'none' }} >
        <Form.Item label = 'Nombre de archivo' name = "filename" >
          <Input />
        </Form.Item>
      </Col>

      <Col span = { 24 } >
        <Form.Item label = 'Aprobado por:' name = "approve" style = {{ display: expand ? 'block' : 'none' }} >
          <Select mode = "multiple" placeholder = "Seleccione un usuario" >
            { users?.type === USERS.READ.READED_USERS
                ? users?.users?.map( ref => (
                    <Select.Option key = { ref.id } value = { `${ ref.data['Nombre completo'] }/${ ref.data['Email'] }` } >
                      { ref.data['Nombre completo'] }
                    </Select.Option>
                  )) : [] }
          </Select>
        </Form.Item>
      </Col>

    </Row>
  );
}