/**
 * Importación de las dependencias del proyecto
 */
import React, { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Drawer, Skeleton, Result, Tabs } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';

import { useAudit } from './context';

/**
 * Función que permite serializar los datos que llegan de firestore
 * para que puedan ser visualizados desde el componente
 * @param {Object} data Objeto que presenta los datos no serializados
 * @returns retorna los datos ya serializados
 */
const serializeData = data => {
    Object.getOwnPropertyNames(data).forEach(prop => {
        data[prop] = data[prop]?.firestore
            ? data[prop]?.path
            : data[prop]?.toDate ? data[prop].toDate()?.toLocaleDateString('es-CO')
            : Array.isArray(data[prop]) ? data[prop].map(value => {
                return value?.firestore
                    ? value?.path
                    : value?.toDate ? value.toDate()?.toLocaleDateString('es-CO')
                    : typeof value === "object" ? serializeData(value) : value;
            }) : typeof data[prop] === "object" ? serializeData(data[prop]) : data[prop];
    }); return data;
};

/**
 * Componente de función que permite mostrar los datos antes y despúes
 * de los eventos realizados en la base de datos.
 * @returns {React.ReactNode}
 */
export const EventDataView = () => {

    // Hooks que permiten utilizar los servicios de firebase
    const firestore = useFirestore();
    // Hooks para el manejo interno del componente
    const [jsonData, setJsonData] = useState({ before: {}, after: {} });
    const [isLoading, setIsLoading] = useState(false)
    // Obtiene una instancia del contexto del log de auditoria
    const { eventData } = useAudit();

    /**
     * Función que establece el estado incial del componente y se ejecuta
     * cada vez que alguno de los parametros firestore, eventData.reference cambia.
     */
    useEffect(() => {
        (async () => {

            if (!eventData.reference) return;
            
            setIsLoading(true);
            const before = (await firestore.doc(eventData.reference).collection('before').get());
            const after = (await firestore.doc(eventData.reference).collection('after').get());

            setJsonData({
                before: before.empty ? {} : serializeData(before.docs[0]?.data()),
                after: after.empty ? {} : serializeData(after.docs[0]?.data())
            });

            setIsLoading(false);

        })();
    }, [firestore, eventData.reference])

    // Retorno el componente de visualización de los datos
    console.log(eventData)
    return (
        <Drawer
            title = "Datos del evento"
            width = { 780 }
            visible = { eventData.visible }
            onClose = { () => eventData.set({ visible: false, reference: undefined, type: undefined }) }
            destroyOnClose = { true } >
            <Skeleton loading = { isLoading } >
            <Result
                    title = { eventData.type }
                    subTitle = { `Datos antes y despúes del evento` }
                    icon = { <DatabaseOutlined /> } >
                    <Tabs type = "card" >
                        <Tabs.TabPane tab = "Antes" key = "before" >
                            <ReactJson
                                src = { jsonData.before }
                                name = { false }
                                displayDataTypes = { false }
                                sortKeys = { true }
                                collapseStringsAfterLength = { 50 } />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab = "Despúes" key = "after" >
                            <ReactJson
                                src = { jsonData.after }
                                name = { false }
                                displayDataTypes = { false }
                                sortKeys = { true }
                                collapseStringsAfterLength = { 50 } />
                        </Tabs.TabPane>
                    </Tabs> 
                </Result>
            </Skeleton>
        </Drawer>
    )
};