/**
 * Constantes de tipos que permite asignar los estados de la aplicación 
 */

export const AUTH = {
  LOGGINGIN: 'LOGGINGIN',
  LOGGEDIN: 'LOGGEDIN',
  LOGGINGOUT: 'LOGGINGOUT',
  LOGGEDOUT: 'LOGGEDOUT',
  CANCELED: 'CANCELED',
  ERROR: 'ERROR'
}

export const UPLOAD = {
  UPLOAD: 'UPLOAD',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  ERROR: 'ERROR'
}

export const ROLES = {
  GET_ROLES: 'GET_ROLES',
  GETTING_ROLES: 'GETTING_ROLES',
  GETTED_ROLES: 'GETTED_ROLES',
  ERROR: 'ERROR'
}

export const DOCUMENTS = {
  
  CREATE: {
    CREATE_DOCUMENTS: 'CREATE_DOCUMENTS',
    CREATING_DOCUMENTS: 'CREATING_DOCUMENTS',
    CREATED_DOCUMENTS: 'CREATED_DOCUMENTS',
    ERROR: 'ERROR'
  },

  READ: {
    READ_DOCUMENTS: 'READ_DOCUMENTS',
    READING_DOCUMENTS: 'READING_DOCUMENTS',
    READED_DOCUMENTS: 'READED_DOCUMENTS',
    ERROR: 'ERROR'
  },

  UPDATE: {
    UPDATE_DOCUMENTS: 'UPDATE_DOCUMENTS',
    UPDATING_DOCUMENTS: 'UPDATING_DOCUMENTS',
    UPDATED_DOCUMENTS: 'UPDATED_DOCUMENTS',
    ERROR: 'ERROR'
  },

  DELETE: {
    DELETE_DOCUMENTS: 'DELETE_DOCUMENTS',
    DELETING_DOCUMENTS: 'DELETING_DOCUMENTS',
    DELETED_DOCUMENTS: 'DELETED_DOCUMENTS',
    ERROR: 'ERROR'
  },

  CLOSE: {
    CLOSE_DOCUMENTS: 'CLOSE_DOCUMENTS',
    CLOSING_DOCUMENTS: 'CLOSING_DOCUMENTS',
    CLOSED_DOCUMENTS: 'CLOSED_DOCUMENTS',
    ERROR: 'ERROR'
  },

  ACCOUNTING: {
    ACCOUNTING_THROW_BACK: 'ACCOUNTING_THROW_BACK',
    ERROR: 'ERROR'
  },

  CLONE: {
    CLONE_DOCUMENTS: 'CLONE_DOCUMENTS',
    CLONING_DOCUMENTS: 'CLONING_DOCUMENTS',
    CLONED_DOCUMENTS: 'CLONED_DOCUMENTS',
    ERROR: 'ERROR'
  },

  SEARCHER: {
    SEARCH_DOCUMENTS: 'SEARCH_DOCUMENTS',
    SEARCHING_DOCUMENTS: 'SEARCHING_DOCUMENTS',
    FOUND_DOCUMENTS: 'FOUND_DOCUMENTS',
    NOT_FOUND_DOCUMENTS: 'NOT_FOUND_DOCUMENTS',
    ERROR: 'ERROR'
  }

}

export const CUSTOMERS = {
  
  CREATE: {
    CREATE_CUSTOMERS: 'CREATE_CUSTOMERS',
    CREATING_CUSTOMERS: 'CREATING_CUSTOMERS',
    CREATED_CUSTOMERS: 'CREATED_CUSTOMERS',
    ERROR: 'ERROR'
  },

  READ: {
    READ_CUSTOMERS: 'READ_CUSTOMERS',
    READING_CUSTOMERS: 'READING_CUSTOMERS',
    READED_CUSTOMERS: 'READED_CUSTOMERS',
    ERROR: 'ERROR'
  },

  UPDATE: {
    UPDATE_CUSTOMERS: 'UPDATE_CUSTOMERS',
    UPDATING_CUSTOMERS: 'UPDATING_CUSTOMERS',
    UPDATED_CUSTOMERS: 'UPDATED_CUSTOMERS',
    ERROR: 'ERROR'
  },

  DELETE: {
    DELETE_CUSTOMERS: 'DELETE_CUSTOMERS',
    DELETING_CUSTOMERS: 'DELETING_CUSTOMERS',
    DELETED_CUSTOMERS: 'DELETED_CUSTOMERS',
    ERROR: 'ERROR'
  }
  
}

export const USERS = {
  
  CREATE: {
    CREATE_USERS: 'CREATE_USERS',
    CREATING_USERS: 'CREATING_USERS',
    CREATED_USERS: 'CREATED_USERS',
    ERROR: 'ERROR'
  },

  READ: {
    READ_USERS: 'READ_USERS',
    READING_USERS: 'READING_USERS',
    READED_USERS: 'READED_USERS',
    ERROR: 'ERROR'
  },

  UPDATE: {
    UPDATE_USERS: 'UPDATE_USERS',
    UPDATING_USERS: 'UPDATING_USERS',
    UPDATED_USERS: 'UPDATED_USERS',
    ERROR: 'ERROR'
  },

  DELETE: {
    DELETE_USERS: 'DELETE_USERS',
    DELETING_USERS: 'DELETING_USERS',
    DELETED_USERS: 'DELETED_USERS',
    ERROR: 'ERROR'
  }
  
}

export const REFERENCES = {
  
  CREATE: {
    CREATE_REFERENCE: 'CREATE_REFERENCE',
    CREATING_REFERENCE: 'CREATING_REFERENCE',
    CREATED_REFERENCE: 'CREATED_REFERENCE',
    ERROR: 'ERROR'
  },

  READ: {
    READ_REFERENCE: 'READ_REFERENCE',
    READING_REFERENCE: 'READING_REFERENCE',
    READED_REFERENCE: 'READED_REFERENCE',
    ERROR: 'ERROR'
  },

  UPDATE: {
    UPDATE_REFERENCE: 'UPDATE_REFERENCE',
    UPDATING_REFERENCE: 'UPDATING_REFERENCE',
    UPDATED_REFERENCE: 'UPDATED_REFERENCE',
    ERROR: 'ERROR'
  },

  DELETE: {
    DELETE_REFERENCE: 'DELETE_REFERENCE',
    DELETING_REFERENCE: 'DELETING_REFERENCE',
    DELETED_REFERENCE: 'DELETED_REFERENCE',
    ERROR: 'ERROR'
  }
  
}

export const FOLDERS = {

  TEMPLATES: {
    CREATE: {
      CREATE_TEMPLATE: 'CREATE_TEMPLATE',
      CREATING_TEMPLATE: 'CREATING_TEMPLATE',
      CREATED_TEMPLATE: 'CREATED_TEMPLATE',
      ERROR: 'ERROR'
    },
  
    READ: {
      READ_TEMPLATE: 'READ_TEMPLATE',
      READING_TEMPLATE: 'READING_TEMPLATE',
      READED_TEMPLATE: 'READED_TEMPLATE',
      ERROR: 'ERROR'
    },
  
    UPDATE: {
      UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
      UPDATING_TEMPLATE: 'UPDATING_TEMPLATE',
      UPDATED_TEMPLATE: 'UPDATED_TEMPLATE',
      ERROR: 'ERROR'
    },
  
    DELETE: {
      DELETE_TEMPLATE: 'DELETE_TEMPLATE',
      DELETING_TEMPLATE: 'DELETING_TEMPLATE',
      DELETED_TEMPLATE: 'DELETED_TEMPLATE',
      ERROR: 'ERROR'
    }
  }

}