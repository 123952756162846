/**
 * Importación de las dependencias del proyecto
 */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { notification } from 'antd';
import moment from 'moment';

/**
 * Crea un contexto que permite compartir el estado de los componentes
 * del Log de auditoria a traves del Hook useAudit;
 */
const AuditContext = React.createContext();
export const useAudit = () => useContext(AuditContext);

/**
 * Componente de función que representa el contexto
 * del Log de auditoria
 * @param {React.ReactChildren} children Nodos hijos del componente
 * @returns {React.ReactNode}
 */
export default ({ children }) => {
    
    // Hooks que permiten utilizar los servicios de firebase
    const firestore = useFirestore();
    const firebase = useFirebase();

    // Hooks para el manejo interno del componente
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState({ source: [], selected: [] });
    const [customers, setCustomers] = useState({ source: [], selected: [] });
    const [eventData, setEventData] = useState({ visible: false, reference: undefined, type: undefined });
    const [eventType, setEventType] = useState([]);
    const [eventDate, setEventDate] = useState({
        start: moment().hour(0).minute(0).second(0).millisecond(0),
        end: moment().hour(23).minute(59).second(59).millisecond(999)
    });

    // Hook que permite obtener los roles del usuario del estado global de la aplicación
    const roles = useSelector(state => state.get('roles').get('roles').roles);
    // Obtiene el id del usuario actual
    const uid = firebase.auth().currentUser.uid;

    /**
     * Función que establece el estado incial del componente y se ejecuta
     * cada vez que alguno de los parametros firestore, roles, uid cambia.
     */
    useEffect(() => {
        (async () => {
            try {

                setIsLoading(true);
                const all_users = (await firestore.collection('users').get()).docs;
                const all_customers = (await firestore.collection('customers').get()).docs;
                const current_user = (await firestore.collection('users').doc(uid).get()).data();
                const company_user = (await current_user?.['Empresa']?.get());
                const isAdmin = roles?.documents?.level === 'all';

                setUsers({
                    source: isAdmin ? await Promise.all(
                        all_users.map(async user => ({
                            ...user.data(),
                            uid: user.ref.id,
                            company: { ...(await user.data()?.['Empresa']?.get())?.data(), cid: user.data()?.['Empresa']?.id }
                        }))
                    ) : [{  ...current_user, uid, company: { ...(await current_user?.['Empresa']?.get())?.data(), cid: current_user?.['Empresa']?.id } }],
                    selected: [uid]
                });

                setCustomers({
                    source: isAdmin
                        ? all_customers.map( customer => ({ ...customer.data(), cid: customer.ref.id }))
                        : [{ ...company_user?.data(), cid: company_user.ref.id }],
                    selected: [current_user?.['Empresa']?.id]
                });
                
                setIsLoading(false);

            } catch( error ) {
                setIsLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Se presento un error al tratar de cargar el contexto del log',
                    placement:  'topRight'
                });
            }
        })();
    }, [firestore, roles, uid]);

    // Retorno del contexto del Log de auditoria
    return (
        <AuditContext.Provider
            value = {{
                isLoading,
                isAdmin: roles?.documents?.level === 'all',
                users: { ...users, set: setUsers },
                customers: { ...customers, set: setCustomers },
                eventData: { ...eventData, set: setEventData },
                eventDate: { ...eventDate, set: setEventDate },
                eventType: { types: eventType, set: setEventType },
            }}
        > { children }
        </AuditContext.Provider>
    );
};