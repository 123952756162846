/**
 * Acción de REDUX que permite llevar a cabo todas las operaciones sobre los usuarios 
 */

import { USERS } from '../../../application/types';
import * as _auth from 'firebase';
import { settings } from '../../../config/services';

/**
 * Exporta las funciones para el manejo de los usuarios
 */
export const users = {

  /**
   * Función que permite crear un usuario.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Información del usuario
   */
  create: ({ roles, user }) => (dispatch, state, firebase) => {

    dispatch({ type: USERS.CREATE.CREATING_USERS });

    return new Promise((resolve, reject) => {
      if (roles.create) {

        const db = firebase().firestore();
        const rol = db.collection('roles').doc(user.rol);

        rol.get().then(query => {
          if (query.exists) {

            const app = window.btoa(Math.floor(new Date().valueOf() * Math.random()).toString());
            const auth = _auth.initializeApp(settings.firebase.config, app).auth();

            auth.createUserWithEmailAndPassword(user.email, user.password).then(usr => {

              usr.user.updateProfile({ displayName: user.name }).then(() => {

                const uid = firebase().auth().currentUser.uid;
                const batch = db.batch();

                const log_audit = db.collection('audit').doc();
                const document = db.collection('users').doc(usr.user.uid);

                const after = {
                  'Email': user.email,
                  'Identificacion': user.id,
                  'Nombre completo': user.name,
                  'Empresa': db.collection('customers').doc(user.company),
                  'Rol': rol
                };

                batch.set(log_audit, { uid, eventType: USERS.CREATE.CREATE_USERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
                batch.set(log_audit.collection('after').doc(), after);
                batch.set(document, after);

                batch.commit().then(() => {
                  auth.sendPasswordResetEmail(user.email).then(() => resolve(
                    dispatch({ type: USERS.CREATE.CREATED_USERS, users: usr.user })
                  )).catch(error => reject(
                    dispatch({ type: USERS.CREATE.ERROR, code: error.code, message: error.message })
                  ))
                }).catch(
                  error => reject(
                    dispatch({ type: USERS.CREATE.ERROR, code: error.code, message: error.message })
                  )
                );

              }).catch(error => reject(
                dispatch({ type: USERS.CREATE.ERROR, code: error.code, message: error.message })
              ))

            }).catch(error => reject(
              dispatch({ type: USERS.CREATE.ERROR, code: error.code, message: error.message })
            ))

          } else {
            reject({ code: 'data', message: 'El rol seleccionado no es valido' });
          }
        }).catch(error => reject(
          dispatch({ type: USERS.CREATE.ERROR, code: error.code, message: error.message })
        ))

      }
    })

  },

  /**
   * Función que permite consultar la lista de usuarios.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Filtro que se desea aplicar a la consulta
   */
  read: ({ roles, filter }) => async (dispatch, getState, firebase) => {
    dispatch({ type: USERS.READ.READING_USERS });
  
    if (!roles.read) {
      dispatch({ type: USERS.READ.ERROR, code: 0, message: 'Permisos insuficientes para consultar la lista de usuario' });
      return;
    }
  
    try {
      const db = firebase().firestore();
      const collection = db.collection('users');
      const query = filter?.value?.trim()?.length > 0 
        ? collection.where(filter.field, '>=', filter.value).where(filter.field, '<=', filter.value + '\uf8ff')
        : collection;
      const snapshot = await query.get();
      console.log("🚀 ~ read: ~ snapshot:", snapshot)
  
      const users = await Promise.all(
        snapshot.docs.map(async doc => ({
          id: doc.id,
          data: { ...doc.data(), 'Empresa': (await doc.data()?.['Empresa']?.get()) }
        }))
      );
  
      dispatch({ type: USERS.READ.READED_USERS, users });
    } catch (error) {
      dispatch({ type: USERS.READ.ERROR, code: error.code, message: error.message });
    }
  },
  // read: ({ roles, filter }) => ( dispatch, state, firebase ) => {

  //   dispatch({ type: USERS.READ.READING_USERS });

  //   return new Promise( async ( resolve, reject ) => {
  //     if ( roles.read ) {
  //       try {

  //         const db = firebase().firestore();
  //         let query = await (
  //           filter?.value?.trim()?.length > 0 
  //             ? db.collection('users').where( filter.field, '==', filter.value )
  //             : db.collection('users') ).get();

  //         const users = !Array.isArray( query.docs )
  //           ? { id: query.id, data: query.data }
  //           : await Promise.all(
  //             query.docs.map(
  //               async doc => ({
  //                 id: doc.id,
  //                 data: { ...doc.data(), 'Empresa': (await doc.data()?.['Empresa']?.get()) }
  //               })
  //             )
  //           );

  //         resolve(dispatch({ type: USERS.READ.READED_USERS, users }));

  //       } catch ( error ) {
  //         reject(
  //           dispatch({ type: USERS.READ.ERROR, code: error.code, message: error.message })
  //         );
  //       }
  //     } else {
  //       reject(
  //         dispatch({ type: USERS.READ.ERROR, code: 0, message: 'Permisos insuficientes para consultar la lista de usuario' })
  //       )
  //     }
  //   })

  // },

  /**
   * Función que permite actualizar la información de un usuario.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Información del usuario
   * ID del usuario
   */


  update: ({ roles, user, id }) => (dispatch, state, firebase) => {

    dispatch({ type: USERS.UPDATE.UPDATING_USERS });

    return new Promise((resolve, reject) => {
      if (roles.update) {

        const db = firebase().firestore();
        const document = db.collection('users').doc(id);
        const rol = db.collection('roles').doc(user.rol);

        rol.get().then(async query => {
          if (query.exists) {

            const uid = firebase().auth().currentUser.uid;
            const batch = db.batch();

            const log_audit = db.collection('audit').doc();
            const before = (await document.get()).data();
            const after = {
              ...before,
              'Identificacion': user.id,
              'Nombre completo': user.name,
              'Empresa': db.collection('customers').doc(user.company),
              'Rol': rol
            };

            batch.set(log_audit, { uid, eventType: USERS.UPDATE.UPDATE_USERS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
            batch.set(log_audit.collection('before').doc(), before);
            batch.set(log_audit.collection('after').doc(), after);
            batch.update(document, after);

            batch.commit().then(() => {
              resolve(dispatch({ type: USERS.UPDATE.UPDATED_USERS }));
            }).catch(
              error => reject(
                dispatch({ type: USERS.UPDATE.ERROR, code: error.code, message: error.message })
              )
            );

          } else {
            reject({ code: 'data', message: 'El rol seleccionado no es valido' });
          }
        }).catch(error => reject(
          dispatch({ type: USERS.READ.ERROR, code: error.code, message: error.message })
        ))

      }
    })

  }

}