import React from 'react';
import { Modal, Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

/**
 * Exporta el componente ModalAddUser
 */
export const ModalAddUser = ({ users, onAddUser, onCancel }) => {

    // Hook para el control de los formularios
    const [form] = Form.useForm();

    return (
        <Modal
            forceRender
            visible = { users.visible }
            confirmLoading = { users.loading }
            title = "Agregar usuario"
            okText = "Agregar"
            cancelText = "Cancelar"
            maskClosable = { false }
            closable = { false }
            onOk = { () => form.submit() }
            onCancel = { () => {
                form.resetFields();
                onCancel();
            }} >
            <Form name = "add_user_to_customer" form = { form } layout = "vertical"
                onFinish = { values => {
                    onAddUser({
                        values,
                        onError: error => form.setFields([
                            { name: "email", value: values.email, errors: [ error.message ] }
                        ])
                    });
                    form.resetFields();
                }}
            >
                <Form.Item name = "email" label = "Email"
                    rules = {[
                        { required: true, message: 'La direccion de email es requerida' },
                        { type: 'email', message: 'Debe ingresar una direccion de correo valida' }
                    ]} >
                    <Input prefix = { <UserOutlined style = {{ color: 'rgba(0,0,0,.25)' }} /> } type = 'email' placeholder = 'Email'  />
                </Form.Item>
            </Form>
        </Modal>
    );
};