import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Select } from 'antd';
import { documents as documentsActions } from '../../actions/database/documents';
import { customers as customersActions } from '../../actions/database/customers';
import { CUSTOMERS, UPLOAD, DOCUMENTS } from '../../../application/types';
import { DocumentsContext } from './context';
import { ListFolders, useListFolder } from './list_folders';
import { ModalFolder, useModalFolder } from './modal_folder';
import { ModalTemplate, useModalTemplate } from './modal_template';
import { ModalSearchFile, useModalSearchFile } from './modal_search_file';
import { Permissions } from './permissions';
import { File } from './file';

export const Documents = () => {
  const dispatch = useDispatch();
  const docs = useSelector(state => state.get('documents').get('read'));
  const auth = useSelector(state => state.get('authenticate').get('login'));
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const customers = useSelector(state => state.get('customers').get('read'));

  const [select, setSelect] = useState("NIT");
  const [clientNames, setClientNames] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [form] = useModalFolder();
  const { templates } = useModalTemplate();
  const { search } = useModalSearchFile();
  const { folders } = useListFolder({ form });

  const loadCustomers = value => {
    folders.set({ ...folders.data, isLoad: false });
    folders.current.isUpload.set(false);
    folders.list.set({ loading: false, columns: [], source: [] });

    if (!value || value.trim().length <= 0) {
      if (select === "Razon social") {
        dispatch(
          customersActions.read({
            roles: roles.documents,
            filter: { field: select, value: "" }
          })
        ).then(({ customers }) => {
          const clientNames = customers.map(customer => ({
            name: customer.data['Razon social'],
            id: customer.id
          }));
          setClientNames(clientNames);
          console.log("Clientes encontrados:", clientNames.map(client => client.name));
          folders.set({ ...folders.data, isLoad: true });
        });
      } else {
        dispatch({ type: CUSTOMERS.READ.READ_CUSTOMERS });
      }
      return;
    }

    dispatch(
      customersActions.read({
        roles: roles.documents,
        filter: { field: select, value }
      })
    ).then(({ customers }) => {
      const clientNames = customers.map(customer => ({
        name: customer.data['Razon social'],
        id: customer.id
      }));
      setClientNames(clientNames);
      console.log("Clientes encontrados:", clientNames.map(client => client.name));
      Promise.all(
        customers.map(customer =>
          dispatch(documentsActions.read({ uid: customer.id, roles: roles.documents })).then(({ data }) => {
            const isUsers = Array.isArray(customer.data['Usuarios']);
            const user = isUsers ? customer.data['Usuarios'].filter(user => user.reference.id === auth.user.uid)[0] : [];

            folders.routes.set([{ name: customer.data['Razon social'], reference: data }]);
            folders.current.reference.set(data);
            folders.current.user.isRoot.set(roles?.documents?.level === 'all');
            folders.current.user.isAdmin.set(user?.isAdmin ? true : false);
            folders.current.user.permissions.set({ visible: false, users: customer?.data['Usuarios'] || [] });
            folders.set({ ...folders.data, isLoad: true });
          })
        )
      ).catch(() => folders.list.set({ loading: false, columns: [], source: [] }));
    });
  };

  useEffect(() => {
    dispatch({ type: CUSTOMERS.READ.READ_CUSTOMERS });
    dispatch({ type: UPLOAD.UPLOAD });
    dispatch({ type: DOCUMENTS.READ.READ_DOCUMENTS });

    if (select === "Razon social") {
      loadCustomers("");
    }
  }, [dispatch, select]);

  const handleClientSelect = value => {
    if (!value) {
      setSelectedClient(null);
      loadCustomers("");  // Reload customers to show the full list again
    } else {
      const selected = clientNames.find(client => client.name === value);
      if (selected) {
        setSelectedClient(selected.name);
        loadCustomers(selected.name);
      }
    }
  };

  return (
    <DocumentsContext.Provider value={{ folders, templates, search }}>
      <div style={{ marginBottom: 15, display: 'flex', alignItems: 'center' }}>
        <Select
          defaultValue="NIT"
          style={{ width: 120, marginRight: 10 }}
          onSelect={value => setSelect(value)}
        >
          <Select.Option value="NIT">Documentos</Select.Option>
          <Select.Option value="Razon social">Nombre</Select.Option>
        </Select>
        {select === "Razon social" ? (
          <Select
            showSearch
            style={{ flex: 1 }}
            placeholder="Seleccione un cliente"
            optionFilterProp="children"
            onChange={handleClientSelect}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {clientNames.map(client => (
              <Select.Option key={client.id} value={client.name}>
                {client.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input.Search
            enterButton="Buscar"
            style={{ flex: 1 }}
            onSearch={loadCustomers}
          />
        )}
      </div>

      {customers?.type === CUSTOMERS.READ.READED_CUSTOMERS
        ? customers?.customers?.length > 0
          ? <ListFolders /> : null
        : customers?.type === CUSTOMERS.READ.READING_CUSTOMERS
          ? <div style={{ textAlign: 'center' }}><Spin /></div>
          : null}

      {folders.current.user.roles?.folders?.create && !folders.current.isClosing.data
        ? <>
          <ModalFolder form={form} />
          <ModalTemplate />
        </>
        : null}

      {docs?.data && folders.current.isUpload.data ? <ModalSearchFile /> : null}

      <Permissions />
      <File />
    </DocumentsContext.Provider>
  );
};
