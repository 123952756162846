/**
 * Componente Loading que permite renderizar el cargador de la aplicación
 */

import React from 'react';
import { Spin } from 'antd';

import '../styles/loading.scss';

/**
 * Exporta el componente Home
 * 
 */
export const Loading = () => {
  /**
   * Metodo que renderiza el componente
   */
  return (
    <div className = 'loading' >
      <Spin tip = "Cargando..." size = 'large' />
    </div>
  );
};