/**
 * Acción de REDUX que permite llevar a cabo todas las operaciones para subir archivos al storage 
 */

import { UPLOAD, DOCUMENTS } from '../../../application/types';

/**
 * Función que permite subir archivos al storage
 * @param {object} file 
 * @param {object} reference 
 */
export const upload = ( file, reference ) => ( dispatch, state, firebase ) => {

  dispatch({ type: UPLOAD.UPLOADING });
  
  return new Promise( async ( resolve, reject ) => {
    try {

      const db = firebase().firestore();
      const name = `Archivo No - ${ Date.now().toString() }`;
      const ref = firebase().storage().ref().child(`${ reference.path.replace('folders', 'documents').replace('files', 'documents') }/${ name }`);
      const docfile = db.doc(`files/${ name }`);
      const docfolder = reference.doc( name );
      const customer = ( await db.doc(`customers/${ docfolder.path.split("/")[1] }`).get() );

      const snapshot = await ref.put( file );
      const url = await ref.getDownloadURL();

      const uid = firebase().auth().currentUser.uid;
      const batch = db.batch();

      const log_audit = db.collection('audit').doc();
      const after = {
        folder: docfolder,
        customer: customer.data()['Razon social'],
        date_of_upload: new Date( snapshot.metadata.timeCreated ),
        name: file.name,
        type: snapshot.metadata.contentType,
        url: url
      }

      batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.CREATE.CREATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
      batch.set(log_audit.collection('after').doc(), after);
      batch.set(docfile, after);
      batch.set(docfolder, { reference: docfile })
      
      await batch.commit();
      return resolve(
        dispatch({  
          type: UPLOAD.UPLOADED,
          data: snapshot
        }
      ));

    } catch ( error ) {
      return reject(
        dispatch({
          type: UPLOAD.ERROR,
          code: error.code,
          message: error.message
        })
      )
    }
  });

}

/**
 * Función que permite subir el archivo de contabilización al storage
 * @param {object} file 
 * @param {object} reference 
 */
export const accounting = ( file, reference ) => ( dispatch, state, firebase ) => {

  dispatch({ type: UPLOAD.UPLOADING });

  return new Promise( async ( resolve, reject ) => {
    try {

      const db = firebase().firestore();
      const name = `Contabilizacion No - ${ Date.now().toString() }`;
      const ref = firebase().storage().ref().child(`${ reference.data().folder.path.replace('folders', 'documents').replace('files', 'documents') }/${ name }`);
      const accounting = db.doc(`files/${ name }`);

      const snapshot = await ref.put( file );
      const url = await ref.getDownloadURL();

      const uid = firebase().auth().currentUser.uid;
      const batch = db.batch();

      const log_audit = db.collection('audit').doc();
      const after = {
        folder: reference.ref,
        customer: reference.data().customer,
        date_of_upload: new Date( snapshot.metadata.timeCreated ),
        name: file.name,
        type: snapshot.metadata.contentType,
        url: url
      }

      batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.CREATE.CREATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
      batch.set(log_audit.collection('after').doc(), after);
      batch.set(accounting, after);
      batch.update(reference.ref, { accounting })
      
      await batch.commit();
      return resolve(
        dispatch({  
          type: UPLOAD.UPLOADED,
          data: snapshot
        }
      ));

    } catch ( error ) {
      return reject(
        dispatch({
          type: UPLOAD.ERROR,
          code: error.code,
          message: error.message
        })
      )
    }
  });

}

/**
 * Función que permite subir el archivo XML al storage
 * @param {object} file 
 * @param {object} reference 
 */
export const xml = ( file, reference ) => ( dispatch, state, firebase ) => {

  dispatch({ type: UPLOAD.UPLOADING });

  return new Promise( async ( resolve, reject ) => {
    try {

      const db = firebase().firestore();
      const name = `XML No - ${ Date.now().toString() }`;
      const ref = firebase().storage().ref().child(`${ reference.data().folder.path.replace('folders', 'documents').replace('files', 'documents') }/${ name }`);
      const xml = db.doc(`files/${ name }`);

      const snapshot = await ref.put( file );
      const url = await ref.getDownloadURL();

      const uid = firebase().auth().currentUser.uid;
      const batch = db.batch();

      const log_audit = db.collection('audit').doc();
      const after = {
        folder: reference.ref,
        customer: reference.data().customer,
        date_of_upload: new Date( snapshot.metadata.timeCreated ),
        name: file.name,
        type: snapshot.metadata.contentType,
        url: url
      }

      batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.CREATE.CREATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
      batch.set(log_audit.collection('after').doc(), after);
      batch.set(xml, after);
      batch.update(reference.ref, { xml })
      
      await batch.commit();
      return resolve(
        dispatch({  
          type: UPLOAD.UPLOADED,
          data: snapshot
        }
      ));

    } catch ( error ) {
      return reject(
        dispatch({
          type: UPLOAD.ERROR,
          code: error.code,
          message: error.message
        })
      )
    }
  });

}

/**
 * Función que permite subir el archivo vinculado al storage
 * @param {object} file 
 * @param {object} reference 
 */
export const linking = ( file, reference ) => ( dispatch, state, firebase ) => {

  dispatch({ type: UPLOAD.UPLOADING });

  return new Promise( async ( resolve, reject ) => {
    try {

      const db = firebase().firestore();
      const name = `Vinculo No - ${ Date.now().toString() }`;
      const ref = firebase().storage().ref().child(`${ reference.data().folder.path.replace('folders', 'documents').replace('files', 'documents') }/${ name }`);
      const linking = db.doc(`files/${ name }`);

      const snapshot = await ref.put( file );
      const url = await ref.getDownloadURL();

      const uid = firebase().auth().currentUser.uid;
      const batch = db.batch();

      const log_audit = db.collection('audit').doc();
      const after = {
        folder: reference.ref,
        customer: reference.data().customer,
        date_of_upload: new Date( snapshot.metadata.timeCreated ),
        name: file.name,
        type: snapshot.metadata.contentType,
        url: url
      }

      batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.CREATE.CREATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
      batch.set(log_audit.collection('after').doc(), after);
      batch.set(linking, after);
      batch.update(reference.ref, { links: firebase().firestore.FieldValue.arrayUnion( linking ) })
      
      await batch.commit();
      return resolve(
        dispatch({  
          type: UPLOAD.UPLOADED,
          data: snapshot
        }
      ));

    } catch ( error ) {
      return reject(
        dispatch({
          type: UPLOAD.ERROR,
          code: error.code,
          message: error.message
        })
      )
    }
  });

}