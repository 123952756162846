/**
 * Controla la configuración de la aplicación
 */

export const settings = {

  // firebase config

  firebase: {
    config: {
      apiKey: "AIzaSyAhmA7ZGVwMCDAj3y3EusuU5ZoHHdQJ7PE",
      authDomain: "daysi-alimso.firebaseapp.com",    
      databaseURL: "https://daysi-alimso.firebaseio.com",    
      projectId: "daysi-alimso",    
      storageBucket: "daysi-alimso.appspot.com",    
      messagingSenderId: "382811839138",    
      appId: "1:382811839138:web:126679017e463bee0cd5fb",    
      measurementId: "G-WCEEZ9XYXJ"
    }
  },

  // react-redux-firebase config

  reactReduxFirebase: {
    config: {
      userProfile: 'users',
      useFirestoreForProfile: true
    }
  }

}