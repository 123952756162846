/**
 * Componente Users que permite llevar a cabo las operaciones de los usuarios
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Input, Select, Table, Spin, Button, Form, Modal, Divider } from 'antd';

import { users } from '../../actions/database/users';
import { USERS } from '../../../application/types';

/**
 * Exporta el componente Search
 */
export const Users = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const roles = useSelector(state => state.get('roles').get('roles').roles);
  const read = useSelector(state => state.get('users').get('read'));

  // Manejo de los estados del componente
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [select, setSelect] = useState("Identificacion");
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState({ key: [], data: [] });
  const [customers, setCustomers] = useState([]);

  // Hook para el control de los formularios
  const [form] = Form.useForm();
  // Hook que permite utilizar las funciones de Firestore
  const firestore = useFirestore();

  /**
   * Función que se ejecuta al crear el usuario
   */
  const onOk = () => form.submit();
  
  /**
   * Función que permite crear un usuario
   */
  const onCreate = values => {
    setLoading(true);
    setVisible(true);

    dispatch(
      users.create({
        roles: roles.users,
        user: {
          email: values.email,
          id: values.id,
          name: values.name,
          rol: values.rol,
          password: values.password,
          company: values.company
        }
      })
    ).then( () => {
      
      form.resetFields();
      setLoading(false);
      setVisible(false);
      onRead();
    
    }).catch( error => {
      
      form.setFields([{ name: "rol", value: values.rol, errors: [ error.message ] }]);
      setVisible(true);
      setLoading(false);

    })
  };

  /**
   * Función que permite actualizar la información de un usuario
   */
  const onUpdate = values => {
    setLoading(true);
    setVisible(true);
    dispatch(
      users.update({
        roles: roles.users,
        user: {
          email: values.email,
          id: values.id,
          name: values.name,
          rol: values.rol,
          password: values.password,
          company: values.company
        },
        id: row.key[0]
      })
    ).then( () => {
      
      form.resetFields();
      setLoading(false);
      setVisible(false);
      setRow({ key: [], data: [] });
      onRead();
    
    }).catch( error => {
      
      form.setFields([{ name: "rol", value: values.rol, errors: [ error.message ] }]);
      setVisible(true);
      setLoading(false);

    })
  };

  /**
   * Función que permite consultar la lista de usuarios
   */
  const onRead = filter => {
    setLoading(true);
    dispatch(
      users.read({ 
        roles: roles.users,
        filter: filter
      })
    ).then( () => setLoading(false) ).catch( () => setLoading(false) );
  };

  // Ejecuta la funcion de consulta de usuario una vez termina de renderizar el componente
  useEffect(() => {
    setLoading(true);
    dispatch( users.read({ roles: roles.users }) ).then( () => setLoading(false) ).catch( () => setLoading(false) );
  }, [dispatch, roles.users]);

  // Obtiene la lista de empresas una vez términa de renderizar el componente
  useState(() => {
    (async () => {
      setCustomers(
        (await firestore.collection('customers').get()).docs.map(doc => ({
          name: doc.data()?.['Razon social'],
          cid: doc.id
        })) || [] );
    })();
  }, []);

  /**
   * Metodo que renderiza el componente
   */
  return (
    <div>

      <div style = {{ marginBottom: 15 }} >
        <Input.Search
          enterButton = "Search"
          onSearch = { value => onRead({ field: select, value: value }) }
          addonBefore = { (
            <Select
              defaultValue = "Identificacion"
              style = {{ width: 120 }}
              onSelect = { value => setSelect(value) }
            >
              <Select.Option value = "Identificacion" > Identificacion </Select.Option>
              <Select.Option value = "Nombre completo" > Nombre completo </Select.Option>
              <Select.Option value = "Email" > Email </Select.Option>
            </Select>
          )}
        />
      </div>

      { read?.type === USERS.READ.READED_USERS ?
          <Table
            bordered
            size = 'middle'
            loading = { loading }
            locale = {{ emptyText: 'La lista de usuarios se encuentra vacia' }}
            title = { () => (
              <>
                
                { roles?.users?.create ?
                    <Button type = 'dashed'
                      disabled = { read?.type !== USERS.READ.READED_USERS }
                      onClick = { () => {
                        form.resetFields();
                        setVisible(true);
                        setUpdate(false);
                      }}
                    > Nuevo usuario </Button> : null }

                <Divider type = "vertical" />                        

                { roles?.users?.update ? 
                    <Button type = 'dashed'
                      disabled = { !row.key.length > 0 }
                      onClick = { () => {
                        setVisible(true);
                        setUpdate(true);
                        form.setFields([
                          { name: "name", value: row?.data[0]?.name },
                          { name: "id", value: row?.data[0]?.id },
                          { name: "email", value: row?.data[0]?.email },
                          { name: "password", value: 'xxxxxxxxxxxx' },
                          { name: "rol", value: row?.data[0]?.rol },
                          { name: "company", value: row?.data[0]?.cid }
                        ]);
                      }}
                    > Editar usuario </Button> : null }

              </>
            )}
            columns = {[
              { title: 'Identificacion', dataIndex: 'id', key: 'id' },
              { title: 'Nombre', dataIndex: 'name', key: 'name' },
              { title: 'Empresa', dataIndex: 'company', key: 'company' },
              { title: 'Email', dataIndex: 'email', key: 'email' },
              { title: 'Rol', dataIndex: 'rol', key: 'rol' },
            ]}
            dataSource = {
              read?.users?.map( user => {
                return {
                  key: user.id,
                  id: user.data['Identificacion'],
                  name: user.data['Nombre completo'],
                  company: user.data?.['Empresa']?.data()?.['Razon social'] || 'Sin una empresa asociada',
                  email: user.data['Email'],
                  rol: user.data['Rol'].id,
                  cid: user.data?.['Empresa']?.ref.id || undefined
                }
              })
            }
            rowSelection = {{
              type: 'radio',
              selectedRowKeys: row.key,
              onChange: ( key, row ) => setRow({ key, data: row }) 
            }}
          />
        : read?.type === USERS.READ.READING_USERS ? <div style = {{ textAlign: 'center' }} > <Spin /> </div> : null }

      { roles?.users?.create || roles?.users?.update ?
          <Modal
            forceRender
            visible = { visible }
            confirmLoading = { loading }
            title = { !update ? "Nuevo usuario" : "Editar usuario" }
            okText = { !update ? "Crear" : "Editar" }
            cancelText = "Cancelar"
            maskClosable = { false }
            closable = { false }
            onOk = { onOk }
            onCancel = { () => setVisible(false) } >
            <Form form = { form } name = "users" onFinish = { !update ? onCreate : onUpdate } layout = "vertical" >
              <Form.Item label = "Nombre completo" name = "name"
                rules = {[{ required: true, message: 'El nombre es requerido' }]} >
                <Input />
              </Form.Item>
              <Form.Item label = "Identificacion" name = "id"
                rules = {[{ required: true, message: "El numero de identificacion es requerido" }]} >
                <Input />
              </Form.Item>
              <Form.Item label = "Email" name = "email"
                rules = {[
                  { required: true, message: 'La direccion de email es requerida' },
                  { type: 'email', message: 'Debe ingresar una direccion de correo valida' }
                ]} >
                <Input />
              </Form.Item>
              <Form.Item label = "Password" name = "password"
                rules = {[
                  { required: true, message: 'El password es requerido' },
                  { min: 6, message: 'El password debe tener minimo 6 caracteres' }
                ]} >
                <Input type = 'password' disabled = { update } />
              </Form.Item>
              <Form.Item label = "Rol" name = "rol"
                rules = {[{ required: true, message: 'El rol es requerido' }]} >
                <Input />
              </Form.Item>
              <Form.Item label = "Empresa" name = "company"
                rules = {[{ required: true, message: "La empresa es requerida" }]} >
                <Select >
                  { customers.map((customer, index) => (
                    <Select.Option key = { index } value = { customer.cid } > { customer.name } </Select.Option>
                  )) }
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        : null }

    </div>
  );
};