/**
 * Acción de REDUX que permite llevar a cabo todas las operaciones sobre las carpetas, archivos y buscador 
 */

import { DOCUMENTS } from '../../../application/types';
import { useFirestore } from 'react-redux-firebase';
/**
 * Exporta las funciones para el manejo de las carpetas
 */
export const documents = {

  /**
   * Función que permite crear una carpeta en el sistema.
   * Recibe como parametros:
   * La referencia de la base de datos en donde va a hacer creada la carpeta 
   * Roles del usuario que esta realizando la operación
   * Información de la carpeta que se va a crear
   */
  create: ({ reference, roles, folder }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.CREATE.CREATING_DOCUMENTS });

    return new Promise( ( resolve, reject ) => {
      if ( roles.create ) {
        reference.get().then( fields => {
          
          reference = fields.data ? reference : reference.doc(window.btoa( Math.floor( Date.now() * Math.random() ).toString()));

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();

          const log_audit = db.collection('audit').doc();
          const doc = reference.collection(window.btoa( Math.floor( Date.now() * Math.random() ).toString()));
          const eventType = `${ DOCUMENTS.CREATE.CREATE_DOCUMENTS }${ reference.path.includes('templates/folders') ? '_TEMPLATES' : '' }`;

          const after = {
            name: folder.name,
            description: folder.description,
            isUpload: folder.isUpload,
            reference: {
              path: doc.parent,
              id: doc.id
            }
          };

          batch.set(log_audit, { uid, eventType, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit.collection('after').doc(), after);
          batch[fields.exists ? 'update' : 'set'](reference, { folders: firebase().firestore.FieldValue.arrayUnion(after) });
          batch.commit().then(() => {
            resolve(
              dispatch({ type: DOCUMENTS.CREATE.CREATED_DOCUMENTS, data: doc })
            )
          }).catch(
            error => reject(
              dispatch({ type: DOCUMENTS.CREATE.ERROR, code: error.code, message: error.message })
            )
          );

        }).catch( error => reject( dispatch({ type: DOCUMENTS.READ.ERROR, code: error.code, message: error.message }) ))

      } else {
        reject(
          dispatch({ type: DOCUMENTS.CREATE.ERROR, code: 0, message: 'Permisos insuficientes para crear documentos' })
        )
      }
    })

  },

  /**
   * Función que permite consultar las carpetas para un cliente especifico.
   * Recibe como parametros:
   * El ID del cliente 
   * Roles del usuario que esta realizando la operación
   */
  read: ({ uid, roles }) => ( dispatch, state, firebase ) => {
  
    dispatch({ type: DOCUMENTS.READ.READING_DOCUMENTS });
  
    return new Promise( ( resolve, reject ) => {
      if ( roles.read ) {
       
        const db = firebase().firestore();
        const documents = db.collection('folders').doc( uid );
  
        documents.get().then( query => resolve(
          dispatch({
            type: DOCUMENTS.READ.READED_DOCUMENTS,
            data: query.ref
          })
        )).catch( error => reject(
          dispatch({
            type: DOCUMENTS.READ.ERROR,
            code: error.code,
            message: error.message
          })
        ))
  
      } else {
        reject(
          dispatch({ type: DOCUMENTS.READ.ERROR, code: 0, message: 'Permisos insuficientes para consultar la lista de documentos' })
        )
      }
    });
  
  },

  /**
   * Función que permite actualizar una carpeta para un cliente especifico.
   * Recibe como parametros:
   * Información de la carpeta antes de ser modificada con la referencia en la base de datos
   * Roles del usuario que esta realizando la operación
   * Información actualizada de la carpeta
   */
  update: ({ item, roles, values }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.UPDATE.UPDATING_DOCUMENTS });

    return new Promise( ( resolve, reject ) => {
      if ( roles.update ) {

        item.reference.path.get().then( fields => {

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();
          const log_audit = db.collection('audit').doc();
          
          const folders = fields.data().folders;
          const index = folders.findIndex( folder => folder.name === item.name );
          const eventType = `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }${ fields.ref.path.includes('templates/folders') ? '_TEMPLATES' : '' }`;

          const before = { ...folders[ index ] };

          folders[ index ].name = values.name;
          folders[ index ].description = values.description;
          folders[ index ].isUpload = values.isUpload;

          batch.set(log_audit, { uid, eventType, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit.collection('before').doc(), before);
          batch.set(log_audit.collection('after').doc(), folders[ index ]);
          batch.update(fields.ref, { folders });

          return batch.commit().then(() => {
            resolve(
              dispatch({ type: DOCUMENTS.UPDATE.UPDATED_DOCUMENTS, data: fields.ref })
            )
          }).catch(
            error => reject(
              dispatch({ type: DOCUMENTS.UPDATE.ERROR, code: error.code, message: error.message })
            )
          );

        }).catch( error => reject(
          dispatch({
            type: DOCUMENTS.UPDATE.ERROR,
            code: error.code,
            message: error.message
          })
        ))

      } else {
        reject(
          dispatch({ type: DOCUMENTS.UPDATE.ERROR, code: 0, message: 'Permisos insuficientes para actualizar la lista de documentos' })
        )
      }
    })

  },

  /**
   * Función que permite eliminar una carpeta para un cliente especifico.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Información de la carpeta que se elimina con la referencia en la base de datos
   */
  delete: ({ roles, item, isValidateToken = false }) => ( dispatch, state, firebase ) => {
        
    dispatch({ type: DOCUMENTS.DELETE.DELETING_DOCUMENTS });
    return new Promise( async ( resolve, reject ) => {
      console.log(roles)
      if ( roles.delete ) {
        try {
          
          const db = firebase().firestore();
          const token = await db.collection('tokens').where('secret', '==', item.token ? item.token : '').get();
          console.log("Token validation:", item.token);
          if ( isValidateToken && token?.empty ) return resolve(
            dispatch({
              type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
              code: -99,
              message: 'Token invalido! Por favor verifique el token y vuelva a intentarlo.'
            })
          );

          const fields = await item.reference.path.get();
          const folders = fields.data ? fields.data()?.folders : undefined;
      
          if ( folders?.length > 0 ) {

            const index = folders?.findIndex( folder => folder.name === item.name );
     
            const query = await fields.ref.collection( folders[ index ].reference.id ).get();
    
            if (query?.docs?.length > 0) {
          
              // Verifica si hay documentos que tengan una propiedad 'folders' con longitud > 0
              const hasFolders = query.docs.filter(x => x.data()?.folders?.length > 0)?.length > 0;

          
              // Verifica si hay documentos que tengan una propiedad 'reference' definida
              const hasReferences = query.docs.filter(x => x.data()?.reference)?.length > 0;
          
              // Evalúa la condición
              if (hasFolders || hasReferences ) {
            
                  return resolve(
                      dispatch({
                          type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
                          code: -99,
                          message: 'La carpeta no puede ser eliminada porque cuenta con uno o mas elementos en su interior.'
                      })
                  );
              }
          
              // Si ninguna de las condiciones anteriores es verdadera, procede a eliminar los documentos
              for (const doc of query.docs) {
                  console.log(`Eliminando documento con ID: ${doc.id}`);
                  await doc.ref.delete();
              }
          } else {
              console.log("No hay documentos en query.docs.");
          }
          
            const uid = firebase().auth().currentUser.uid;
            const batch = db.batch();

            const log_audit = db.collection('audit').doc();
       
            const eventType = `${ DOCUMENTS.DELETE.DELETE_DOCUMENTS }${ fields.ref.path.includes('templates/folders') ? '_TEMPLATES' : '' }`;
            const before = { ...folders[ index ] };

            batch.set(log_audit, { uid, eventType, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
            batch.set(log_audit.collection('before').doc(), before);
            batch.update(fields.ref, { folders: firebase().firestore.FieldValue.arrayRemove(folders[ index ]) })

            await batch.commit();
            return resolve(
              dispatch({
                type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
                data: item.reference.path
              })
            );

          }

          await item.reference.path.doc( item.reference.id ).delete();
          return resolve(
            dispatch({
              type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
              data: item
            })
          );

        } catch( error ) {
          return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: error.code,
              message: error.message
          })
          );
        }
      } else {
        return reject(
          dispatch({ type: DOCUMENTS.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar la lista de documentos' })
        )
      }
    })

  },

  /**
   * Función que permite cerrar una carpeta para un cliente especifico.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Información de la carpeta que se cierra con la referencia en la base de datos
   */
  close: ({ roles, item }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.CLOSE.CLOSING_DOCUMENTS });

    return new Promise( async ( resolve, reject ) => {
      if ( roles.closing ) {
        try {

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();
          
          const fields = await item.reference.path.get();
          const folders = fields.data().folders;
          const index = folders?.findIndex( folder => folder.name === item.name );
          
          const log_audit = db.collection('audit').doc();
          const before = { ...folders[ index ] };
          
          folders[ index ].close = {
            date: new Date()
          }

          const after = { ...folders[ index ] };

          batch.set(log_audit, { uid, eventType: DOCUMENTS.UPDATE.UPDATE_DOCUMENTS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit.collection('before').doc(), before);
          batch.set(log_audit.collection('after').doc(), after);
          batch.update(fields.ref, { folders })

          await batch.commit();
          return resolve(
            dispatch({
              type: DOCUMENTS.CLOSE.CLOSED_DOCUMENTS,
              data: fields.ref
            })
          );

        } catch ( error ) {
          return reject(
            dispatch({
              type: DOCUMENTS.CLOSE.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.CLOSE.ERROR, code: 0, message: 'Permisos insuficientes para cerrar la carpeta' })
        )
      }
    })

  },

  /**
   * Función que permite abrir una carpeta para un cliente especifico.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Información de la carpeta que se abre con la referencia en la base de datos
   */
  open: ({ roles, item }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.CLOSE.CLOSING_DOCUMENTS });

    return new Promise( async ( resolve, reject ) => {
      if ( roles.closing ) {
        try {

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();
          
          const fields = await item.reference.path.get();
          const folders = fields.data().folders;
          const index = folders?.findIndex( folder => folder.name === item.name );
          
          const log_audit = db.collection('audit').doc();
          const before = { ...folders[ index ] };
          
          delete folders[ index ].close;

          const after = { ...folders[ index ] };

          batch.set(log_audit, { uid, eventType: DOCUMENTS.UPDATE.UPDATE_DOCUMENTS, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit.collection('before').doc(), before);
          batch.set(log_audit.collection('after').doc(), after);
          batch.update(fields.ref, { folders })

          await batch.commit();
          return resolve(
            dispatch({
              type: DOCUMENTS.CLOSE.CLOSED_DOCUMENTS,
              data: fields.ref
            })
          );

        } catch ( error ) {
          return reject(
            dispatch({
              type: DOCUMENTS.CLOSE.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.CLOSE.ERROR, code: 0, message: 'Permisos insuficientes para abrir la carpeta' })
        )
      }
    })

  },

  /**
   * Función que permite asignar permisos a un usuario para una carpeta en especifico.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Referencia de la carpeta en la base de datos
   * Los permisos que se van a asignar
   * ID del usuario al que se van asiganr los permisos
   */
  setPermissions: ({ roles, reference, permissions, uid }) => ( dispatch, state, firebase )  => {

    dispatch({ type: DOCUMENTS.UPDATE.UPDATING_DOCUMENTS });

    return new Promise( async ( resolve, reject ) => {
      if ( roles.update ) {
        try {

          const db = firebase().firestore();
          const currentUser = firebase().auth().currentUser.uid;
          const batch = db.batch();

          const log_audit = db.collection('audit').doc();
          const users = (await reference.collection('users').doc( uid ).get()).data();
          const before = users ? { ...users } : null;
          const after = {
            user: db.collection('users').doc( uid ),
            permissions: {
              documents: permissions.documents,
              folders: permissions.folders
            }
          };

          batch.set(log_audit, { uid: currentUser, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_PERMISSIONS`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          if (before) batch.set(log_audit.collection('before').doc(), before);
          batch.set(log_audit.collection('after').doc(), after);
          batch.set(reference.collection('users').doc( uid ), after);

          await batch.commit();
          return resolve(
            dispatch({
              type: DOCUMENTS.UPDATE.UPDATED_DOCUMENTS,
              data: reference
            })
          )

        } catch ( error ) {
          reject(
            dispatch({
              type: DOCUMENTS.UPDATE.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.UPDATE.ERROR, code: 0, message: 'Permisos insuficientes para actualizar los permisos de las carpetas' })
        )
      }
    });

  },

  /**
   * Función que permite clonar una carpeta a partir de una plantilla.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Referencia de la carpeta en la base de datos
   * Estructura de directorios que van a clonar a partir de la plantilla
   */
  clone: ({ roles, reference, folders }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.CLONE.CLONING_DOCUMENTS });

    return new Promise( async ( resolve, reject ) => {
      if ( roles.create ) {
        try {

          const db = firebase().firestore();

          const createFolder = async () => {
            try {
              for ( const folder of folders ) {

                const id = folder.substring( folder.lastIndexOf("/") + 1, folder.length );
                const item = await db.doc( folder.substring( 0, folder.lastIndexOf("/")) ).get();
                const data = item.data().folders.filter( folder => folder.reference.id === id )[0]
      
                const doc = await dispatch( documents.create({ reference, roles,
                  folder: {
                    name: data.name,
                    description: data.description,
                    isUpload: data.isUpload,
                  }
                }));
      
                let fields = ( await doc?.data.get() );
                fields = typeof fields?.data === "function" ? fields.data()?.folders || [] : [];
                
                const ref = Array.isArray(fields) ? fields[0]?.reference.path.collection( fields[0].reference.id ) || doc?.data : doc?.data;
                await createSubFolder({ ref: item.ref.collection( id ), folder: ref })
      
              }
            } catch (error) { new Error(error) };
          };

          const createSubFolder = async ({ ref, folder }) => {
            try{

              const items = await ref.get();
              for ( const doc of items.docs ) {
                
                const folders = typeof doc?.data === "function" ? doc?.data()?.folders || [] : [];
                for ( const data of folders ) {

                  const docref = await dispatch( documents.create({ reference: folder, roles,
                    folder: {
                      name: data.name,
                      description: data.description,
                      isUpload: data.isUpload,
                    }
                  }));

                  let fields = (await docref.data.get());
                  fields = typeof fields?.data === "function" ? fields.data()?.folders || [] : [];
                  
                  const folderRef = Array.isArray(fields) ? fields[0]?.reference.path.collection( fields[0].reference.id ) || docref?.data : docref?.data;
                  await createSubFolder({ ref: data.reference.path.collection( data.reference.id ), folder: folderRef });
                  
                }
                 

              }

            } catch( error ) { new Error(error) };
          };

          await createFolder();
          resolve({ type: DOCUMENTS.CLONE.CLONED_DOCUMENTS, ref: reference });
        
        } catch (error) {
          reject(
            dispatch({ type: DOCUMENTS.CLONE.ERROR, code: error?.code, message: error?.message })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.CLONE.ERROR, code: 0, message: 'Permisos insuficientes para clonar carpetas' })
        )
      }
    })

  }

}

/**
 * Exporta las funciones para el manejo de los archivos
 */
export const files = {

  /**
   * Función que permite eliminar un archivo de una carpeta especifica.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Referencia del archivo en la base de datos
   */
  delete: ({ roles, reference }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.DELETE.DELETING_DOCUMENTS });

    return new Promise( async ( resolve, reject ) => {
      if ( roles.delete ) {
        try {

          const folder = await reference.get();

          if ( !folder.exists ) return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: 0,
              message: 'El archivo que intenta eliminar no existe'
            })
          );
          
          if ( !(folder.data().folder) ) return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: 0,
              message: 'Estructura de archivo incorrecta'
            })
          );

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();

          const log_audit = db.collection('audit').doc();

          batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.DELETE.DELETE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit.collection('before').doc(), folder.data());
          batch.delete(folder.data().folder);
          batch.delete(reference);

          await batch.commit()
          return resolve(
            dispatch({
              type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
              data: reference
            })
          );

        } catch ( error ) {
          return reject (
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }         
      } else {
        reject(
          dispatch({ type: DOCUMENTS.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar la lista de archivos' })
        )
      }
    })

  },

  /**
   * Función que permite reversar la contabilización de un archivo en una carpeta especifica.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Referencia del archivo en la base de datos
   */
  accountingThrowBack: ({ roles, reference }) => ( dispatch, state, firebase ) => {
    return new Promise( async ( resolve, reject ) => {
      if ( roles.accounting ) {
        try {

          const field = firebase().firestore.FieldValue;
          const file = await reference.get();
          const { accounting, ...rest } = file.data();

          if ( !file.exists ) return reject(
            dispatch({
              type: DOCUMENTS.ACCOUNTING.ERROR,
              code: 0,
              message: 'El archivo que intenta eliminar no existe'
            })
          );
          
          if ( !accounting ) return reject(
            dispatch({
              type: DOCUMENTS.ACCOUNTING.ERROR,
              code: 0,
              message: 'Estructura de archivo incorrecta'
            })
          );

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();

          const log_audit_update = db.collection('audit').doc();
          const log_audit_delete = db.collection('audit').doc();

          batch.set(log_audit_update, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_update.collection('before').doc(), { accounting, ...rest });
          batch.set(log_audit_update.collection('after').doc(), rest);
          
          batch.set(log_audit_delete, { uid, eventType: `${ DOCUMENTS.DELETE.DELETE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_delete.collection('before').doc(), (await accounting.get()).data());
          
          batch.delete(accounting);
          batch.update(reference, { accounting: field.delete() });

          await batch.commit()
          return resolve(
            dispatch({
              type: DOCUMENTS.ACCOUNTING.ACCOUNTING_THROW_BACK,
              data: reference
            })
          );

        } catch ( error ) {
          return reject (
            dispatch({
              type: DOCUMENTS.ACCOUNTING.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.ACCOUNTING.ERROR, code: 0, message: 'Permisos insuficientes para reversar la contabilización' })
        )
      }
    });
  },

  /**
   * Función que permite eliminar un archivo XML vinculado en una carpeta especifica.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Referencia del archivo en la base de datos
   */
  deleteXML: ({ roles, reference }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.DELETE.DELETING_DOCUMENTS })
    return new Promise( async ( resolve, reject ) => {
      if ( roles.delete ) {
        try {

          const field = firebase().firestore.FieldValue;
          const file = await reference.get();
          const { xml, ...rest } = file.data();

          if ( !file.exists ) return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: 0,
              message: 'El archivo que intenta eliminar no existe'
            })
          );
          
          if ( !xml ) return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: 0,
              message: 'Estructura de archivo incorrecta'
            })
          );

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();

          const log_audit_update = db.collection('audit').doc();
          const log_audit_delete = db.collection('audit').doc();

          batch.set(log_audit_update, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_update.collection('before').doc(), { xml, ...rest });
          batch.set(log_audit_update.collection('after').doc(), rest);

          batch.set(log_audit_delete, { uid, eventType: `${ DOCUMENTS.DELETE.DELETE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_delete.collection('before').doc(), (await xml.get()).data());
          
          batch.delete(xml);
          batch.update(reference, { xml: field.delete() });

          await batch.commit()
          return resolve(
            dispatch({
              type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
              data: reference
            })
          );

        } catch ( error ) {
          return reject (
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar archivo XML' })
        )
      }
    });
  },

  /**
   * Función que permite eliminar un archivo vinculado en una carpeta especifica.
   * Recibe como parametros:
   * Roles del usuario que esta realizando la operación
   * Referencia del archivo en la base de datos
   * Referencia del archivo vinculado
   */
  deleteLink: ({ roles, reference, link }) => ( dispatch, state, firebase ) => {

    dispatch({ type: DOCUMENTS.DELETE.DELETING_DOCUMENTS })
    return new Promise( async ( resolve, reject ) => {
      if ( roles.delete ) {
        try {

          const field = firebase().firestore.FieldValue;
          const file = await reference.get();
          const { links, ...rest } = file.data();

          if ( !file.exists ) return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: 0,
              message: 'El archivo que intenta eliminar no existe'
            })
          );
          
          if ( !links ) return reject(
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: 0,
              message: 'Estructura de archivo incorrecta'
            })
          );

          const db = firebase().firestore();
          const uid = firebase().auth().currentUser.uid;
          const batch = db.batch();

          const log_audit_update = db.collection('audit').doc();
          const log_audit_delete = db.collection('audit').doc();

          const before = { links: Object.assign([], links), ...rest };

          links.splice(links.indexOf(link), 1);
          const after = { links, ...rest };

          batch.set(log_audit_update, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_update.collection('before').doc(), before);
          batch.set(log_audit_update.collection('after').doc(), after);
          
          batch.set(log_audit_delete, { uid, eventType: `${ DOCUMENTS.DELETE.DELETE_DOCUMENTS }_FILES`, eventDate: firebase().firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit_delete.collection('before').doc(), (await link.get()).data());
          
          batch.delete(link);
          batch.update(reference, { links: field.arrayRemove( link ) });

          await batch.commit()
          return resolve(
            dispatch({
              type: DOCUMENTS.DELETE.DELETED_DOCUMENTS,
              data: reference
            })
          );

        } catch ( error ) {
          return reject (
            dispatch({
              type: DOCUMENTS.DELETE.ERROR,
              code: error.code,
              message: error.message
            })
          );
        }
      } else {
        reject(
          dispatch({ type: DOCUMENTS.DELETE.ERROR, code: 0, message: 'Permisos insuficientes para eliminar archivo XML' })
        )
      }
    });    

  },

  /**
   * Función que permite descargar un archivo en una carpeta especifica.
   * Recibe como parametros:
   * URL del archivo
   * Nombre del archivo
   */
  download: ( url, fileName ) => async( dispatch, state, firebase ) => {
  
    const storage = firebase().storage();
  
    
  
    storage.refFromURL( url ).getDownloadURL().then( url => {
      fetch( url ).then( res => res.blob() ).then( blob => {
        
        const url = URL.createObjectURL( blob );
        const link = document.createElement('a');

        document.body.appendChild( link );

        link.href = url;
        link.download = fileName;

        if( window.navigator.msSaveOrOpenBlob ) {
          window.navigator.msSaveOrOpenBlob( blob, fileName );
        } else {
          link.click();
        }
        
        document.body.removeChild( link );

        URL.revokeObjectURL( url );
        
      });
    });
    
  }
  
}

/**
 * Exporta la función que ejecuta el buscador para encontrar los archivos en la base de datos
 */
export const searcher = ({ roles, fields }) => ( dispatch, state, firebase ) => {

  // console.log(`Hora de inicio: ${ moment().format('HH:mm:ss') }`);
  dispatch({ type: DOCUMENTS.SEARCHER.SEARCHING_DOCUMENTS });

  return new Promise( async ( resolve, reject ) => {
    if ( roles.read ) {
      try {

        const files = [];
        const db = firebase().firestore();
        const permissions = state().get('roles').get('roles').roles.documents;
        const user = state().get('authenticate').get('login').user;

        const customers = fields.customer[0] === "-1"
          ? permissions.level === "all" 
            ? ( await db.collection('customers').get() ).docs.map( doc => doc.data()['Razon social'] )
            : ( await db.collection('customers')
                  .where("Usuarios", "array-contains", {
                    email: user.email,
                    reference: db.doc(`users/${ user.uid }`)
                  }).get()
              ).docs.map( doc => doc.data()['Razon social'] )
          : fields.customer.map( customer => customer.split("/")[1] );
        
        for ( const customer of customers.sort() ) {

          let collection = db.collection('files').where( 'customer', '==', customer );
          if ( fields.reference || fields.referencevalue ) {

            const name = fields.reference ? fields.reference : ''
            let value = fields.referencevalue ? fields.referencevalue : '';
            
            if ( typeof value === 'object' ) {
              value = value.toDate();
              value.setHours(0);
              value.setMinutes(0);
              value.setSeconds(0);
              value.setMilliseconds(0);
            }
  
            collection = collection.where("references", "array-contains", { name, value });
  
          }
  
          if ( Array.isArray( fields.date_of_upload ) ) {
            
            let date = { start: fields.date_of_upload[0].toDate(), end: fields.date_of_upload[1].toDate() };
  
            date.start.setHours(0);
            date.start.setMinutes(0);
            date.start.setSeconds(0);
            date.start.setMilliseconds(0);
  
            date.end.setHours(23);
            date.end.setMinutes(59);
            date.end.setSeconds(59);
            date.end.setMilliseconds(999);
  
            collection = collection.where("date_of_upload", ">=", date.start).where("date_of_upload", "<=", date.end);          
  
          }

          if ( fields.filename ) collection = collection.where("name", "==", fields.filename);
  
          const query = await collection.get();

          if ( query.docs.length > 0 ) {
            for ( const doc of query.docs ) {

              if ( Array.isArray( fields.approve ) ) {

                let exists = true;
                if ( Array.isArray( doc.data().approve ) ) {
                  fields.approve.forEach( email => {
                    exists = doc.data().approve.includes( email.split("/")[1] );
                  })
                } else exists = false
            
                if ( exists ) files.push({
                  ref: doc.ref,
                  data: doc.data()
                });
            
              } else files.push({
                ref: doc.ref,
                data: doc.data()
              });

            };
          }

        } resolve( files );

      } catch( error ) { reject(
        dispatch({
          type: DOCUMENTS.SEARCHER.ERROR,
          code: error.code,
          message: error.message
        })
      )}
    }
  });

}