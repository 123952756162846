/**
 * Componente File que permite llevar acabo todas las operaciones sobre los archivos
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { Drawer, Form, Button, Tabs, Col, Row, Divider, notification, Modal, Table, Popconfirm, Select, Input, DatePicker, InputNumber, Spin, Slider } from 'antd';
import { DownloadOutlined, DeleteOutlined, EyeOutlined, LeftCircleTwoTone, RightCircleTwoTone, SlidersTwoTone } from '@ant-design/icons';
import moment from 'moment';
//import { Document, Page, pdfjs } from 'react-pdf';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { XMLUpload, AccountingUpload, LinkUpload } from './upload';
import { references as referencesActions } from '../../actions/database/references';
import { files as filesActions } from '../../actions/database/documents';
import { REFERENCES, DOCUMENTS } from '../../../application/types';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useDocuments } from './context';

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Sub Componente que representa la descripción del archivo
 * @param {object} param0 
 */
const DescriptionItem = ({ title, content }) => (
  <div style = {{ fontSize: 14, lineHeight: '22px', marginBottom: 7, color: 'rgba(0,0,0,0.65)' }} >
    <p style = {{ marginRight: 8, display: 'inline-block', color: 'rgba(0,0,0,0.85)' }} >
      {title}:
    </p>
    {content}
  </div>
);

/**
 * Exporta el componente File
 */
export const File = () => {

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToNextPage, jumpToPreviousPage, CurrentPageLabel, NumberOfPages } = pageNavigationPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  const rotatePluginInstance = rotatePlugin();
  const { RotateForwardButton } = rotatePluginInstance;

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();
  // Selecciona los reductores
  const read = useSelector(state => state.get('references').get('read'));
  const auth = useSelector(state => state.get('authenticate').get('login'));

  // Hook para ejecutar funciones de firebase
  const firestore = useFirestore();
  const firebase = useFirebase();

  // Manejo de los estados del componente
  const [selected, setSelected] = useState({ type: "", message: "", component: undefined });
  const [xml, setXml] = useState({ loading: false, source: [] });
  const [accounting, setAccounting] = useState({ loading: false, source: [] });
  const [references, setReferences] = useState({ loading: false, visible: false, source: [] });
  const [links, setLinks] = useState({ loading: false, source: [] });
  const [approve, setApprove] = useState({ loading: false, source: [] });
  const [preview, setPreview] = useState({ loading: false, visible: false, numPages: 1, pageNumber: 1, rotate: 0, scale: 1 });

  // Hook para el control de los formularios
  const [form] = Form.useForm();

  const { folders } = useDocuments();
  const { file, user, isClosing } = folders.current;

  /**
   * Metodo del componente que se ejecuta una vez se cierre la ficha tecnica del archivo
   * Incializa nuevamente las variables del componente
   */
  const onCloseFile = () => {
    form.resetFields();
    file.set({ visible: false, isLoad: false, reference: undefined, fields: {} });
    setSelected("");
    setXml({ loading: false, source: [] });
    setAccounting({ loading: false, source: [] });
    setReferences({ loading: false, visible: false, source: [] });
    setLinks({ loading: false, source: [] });
  };

  /**
   * Metodo del componente que permite eliminar un archivo
   */
  const deleteFile = () => {
    dispatch( filesActions.delete({ roles: user.roles.documents, reference: file.reference?.ref }) ).then( () => {
      onCloseFile();
      folders.set({ ...folders.data, isLoad: true });
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de eliminar el archivo',
        placement:  'topRight'
      })
    });
  };

  /**
   * Metodo del componente que permite cargar el archivo XML subido
   */
  const loadXML = () => {
    setXml({ loading: true, source: [] });
    file.reference.ref.get().then( doc => {

      const { set, ...rest } = file;
      const fields = doc.data();

      set({ ...rest, fields });
      setXml({ loading: fields?.xml ? true : false, source: [] }); 

      if (!fields?.xml) return;
      fields.xml.get().then( file => {
        setXml({
          loading: false,
          source: file.exists ? [{
            key: file.id,
            name: file.data().name,
            url: file.data().url,
            date: file.data().date_of_upload.toDate().toLocaleDateString('es-CO', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
          }] : []
        });
      });

    });
  };

  /**
   * Metodo del componente que permite cargar el archivo de contabilización
   */
  const loadAccounting = () => {
    setAccounting({ loading: true, source: [] });
    file.reference.ref.get().then( doc => {

        const { set, ...rest } = file;
        const fields = doc.data();

        set({ ...rest, fields });
        setAccounting({ loading: fields?.accounting ? true : false, source: [] });

        if (!fields?.accounting) return;
        fields.accounting.get().then( file => {
          setAccounting({
            loading: false,
            source: file.exists ? [{
              key: file.id,
              name: file.data().name,
              url: file.data().url,
              date: file.data().date_of_upload.toDate().toLocaleDateString('es-CO', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
            }] : []
          });  
        });

    });
  };

  /**
   * Metodo del componente que permite cargar las referencias
   */
  const loadReferences = () => {
    setReferences({ loading: true, source: [], visible: false });
    file.reference.ref.collection('references').get().then( query => {

      let source = [];
      setReferences({ loading: !query.empty ? true : false, source: [], visible: false });
      
      if (query.empty) return;
      query.docs.forEach( doc => {

        const data = doc?.data();
        source.push({
          key: `${ data?.name }-${ data?.value }`,
          index: {
            name: data?.name,
            value: data?.value
          },
          ref: data?.name,
          value: typeof data?.value !== "object" ? data?.value : data?.value?.toDate().toLocaleDateString('es-CO', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        });
      
      });

      setReferences({ loading: false, visible: false, source });

    });
  };

  /**
   * Metodo del componente que permite cargar los usuarios aprobadores
   */
  const loadApprove = () => {
    setApprove({ loading: true, source: [] });
    file.reference.ref.get().then( async doc => {

      const { set, ...rest } = file;
      const fields = doc.data();
      let approves = [];

      set({ ...rest, fields });
      for ( const approve of Array.isArray( fields?.approve ) ? fields.approve : [] ) {
        const data = ( await file.reference.ref.collection('approve').doc( approve ).get() ).data();
        if ( data ) {
          const user = ( await data?.user?.get() ).data()
          approves.push({
            name: user['Nombre completo'],
            email: user['Email'],
            date: data?.date_of_approve
          });
        }
      }

      setApprove({
        loading: false,
        source: approves.map( ( approve, index ) => {
          return {
            key: index,
            index: index,
            name: approve?.name,
            email: approve?.email,
            date: approve?.date?.toDate().toLocaleDateString('es-CO', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
          }
        })
      });

    });
  };

  /**
   * Metodo del componente que permite cargar los archivos vinculados
   */
  const loadLinksFiles = () => {
    setLinks({ loading: true, source: [] });
    file.reference.ref.get().then( async doc => {
      
      const { set, ...rest } = file;
      const fields = doc.data();

      set({ ...rest, fields });
      setLinks({ loading: Array.isArray( fields?.links ) ? true : false, source: [] });
      
      if (!Array.isArray( fields?.links )) return;

      const source = [];
      for ( const link of fields?.links ) {

        const file = await link?.get();
        if ( file?.exists ) source.push({
          key: file?.id,
          ref: file?.ref,
          name: file?.data()?.name,
          url: file?.data()?.url,
          date: file?.data()?.date_of_upload?.toDate().toLocaleDateString('es-CO', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        });

      }
      
      setLinks({ loading: false, source });

    });
  };

  /**
   * Metodo del componente que permite reversar la contabilización de un archivo
   */
  const accountingThrowBack = () => {
    dispatch(
      filesActions.accountingThrowBack({
        roles: user.roles.documents,
        reference: file.reference?.ref,
      }
    )).then( () => {
      form.resetFields();
      loadAccounting();
    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de reversar la contabilización',
        placement:  'topRight'
      })
    });
  };

  /**
   * Metodo que se ejecuta cuando se cambia de pestaña y carga la información correspondiente
   * @param {object} key 
   */
  const onChangeTab = key => {
    switch (key) {
      case "1": loadXML(); break;
      case "2": loadAccounting(); break;
      case "3": loadReferences(); break;
      case "4": loadLinksFiles(); break;
      case "5": loadApprove(); break;
      default: loadXML(); break;
    }
  };

  /**
   * Metodo del componente que elimina el archivo XML
   */
  const deleteXML = () => {
    dispatch(
      filesActions.deleteXML({
        roles: user.roles.documents,
        reference: file.reference?.ref,
      }
    )).then( () => loadXML() ).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de eliminar el archivo XML',
        placement:  'topRight'
      })
    });
  };

  /**
   * Metodo que permite eliminar un archivo vinculado
   * @param {object} ref 
   */
  const deleteLinkFile = ref => {
    dispatch(
      filesActions.deleteLink({
        roles: user.roles.documents,
        reference: file.reference?.ref,
        link: ref
      }
    )).then( () => loadLinksFiles() ).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de eliminar el archivo',
        placement:  'topRight'
      })
    });
  };

  /**
   * Metodo que permite abrir una ventana emergente para agregar referencias
   */
  const openDrawerReference = () => {
    form.resetFields();
    setReferences({ loading: true, visible: false, source: references.source });
    dispatch( referencesActions.read({ roles: user.roles.documents?.references }) ).then(
      () => setReferences({ loading: true, visible: true, source: references.source })
    ).catch(
      error => setReferences({ loading: false, visible: false, source: references.source })
    )
  };

  /**
   * Metodo que permite cerrar la ventana emergente de referencias
   */
  const closeDrawerReference = () => {
    form.resetFields();
    setReferences({ loading: false, visible: false, source: references.source });
  };

  /**
   * Metodo que se ejecuta cuando se selección una referencia y carga el tipo de dato
   * @param {object} value 
   */
  const handleSelectChangeReference = value => {
    const ref = read?.reference?.filter( ref => ref.id === value )[0];
    switch ( ref?.type ) {
      case "Numérico":
        setSelected({ type: "number", message: "Debe ingresar un valor númerico", component: <InputNumber style = {{ width: '100%' }} /> });
        form.setFieldsValue({ value: 0 });
        break;
      case "Alfanumérico":
        setSelected({ type: "string", message: "Debe ingresar un valor alfanúmerico", component: <Input style = {{ width: '100%' }} /> });
        form.setFieldsValue({ value: "" });
        break;
      case "Fecha":
        setSelected({ type: "object", message: "Debe ingresar una fecha", component: <DatePicker format = 'DD/MM/YYYY' style = {{ width: '100%' }} /> });
        form.setFieldsValue({ value: moment() });
        break;
      default: break;
    }
  };

  /**
   * Metodo que permite guardar una referencia
   */
  const saveReference = values => {
    
    const val = read?.reference?.filter( ref => ref.id === values.reference )[0];    
    file.reference.ref.get().then( doc => {

      let value = values.value;
      let collection = doc.ref.collection('references');

      if ( val?.type === "Fecha" ) {
        value = value.toDate();
        value.setHours(0);
        value.setMinutes(0);
        value.setSeconds(0);
        value.setMilliseconds(0);
      }

      collection.where( 'name', '==', val?.name ).where( 'value', '==', value ).get().then( query => {
        if ( query.empty ) {

          const uid = firebase.auth().currentUser.uid;
          const batch = firestore.batch();

          const log_audit = firestore.collection('audit').doc();
          const refs = references.source.map(reference => {
            return {
              name: reference.index.name,
              value: reference.index.value
            }
          });

          refs.push({ name: val?.name, value });

          const before = { ...doc.data() };
          const after = { ...doc.data(), references: refs  };

          batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firestore.FieldValue.serverTimestamp() });
          batch.set(log_audit.collection('before').doc(), before);
          batch.set(log_audit.collection('after').doc(), after);
          batch.set(doc.ref.collection('references').doc(), { name: val?.name, value });
          batch.update(doc.ref, { references: firestore.FieldValue.arrayUnion({ name: val?.name, value }) });

          batch.commit().then(() => {
            loadReferences();
          }).catch( error => {
            notification.error({
              message: 'Error',
              description: 'Se presento un error al tratar de agregar la referencia',
              placement:  'topRight'
            })
          });

        } else notification.warn({
          message: 'Referencia duplicada',
          description: 'La referencia ya fue agregada con el mismo valor.',
          placement:  'topRight'
        });
      });

    }).catch( error => {
      notification.error({
        message: 'Error',
        description: 'Se presento un error al tratar de agregar la referencia',
        placement:  'topRight'
      })
    })
  };

  /**
   * Metodo que permite guardar un usuario aprobador
   */
  const saveApprove = () => {
    if ( approve?.source?.filter( user => user.approve === auth?.user?.email )?.length <= 0 ) {

      const uid = firebase.auth().currentUser.uid;
      const batch = firestore.batch();

      const log_audit = firestore.collection('audit').doc();
      const approves = approve.source.map(data => data.email);

      approves.push(auth?.user?.email);

      const before = file.fields;
      const after = { ...file.fields, approve: approves  };

      batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firestore.FieldValue.serverTimestamp() });
      batch.set(log_audit.collection('before').doc(), before);
      batch.set(log_audit.collection('after').doc(), after);
      batch.update(file.reference.ref, { approve: firestore.FieldValue.arrayUnion(auth?.user?.email) });
      batch.set(file.reference.ref.collection('approve').doc( auth?.user?.email ), {
        user: firestore.doc(`users/${ auth?.user?.uid }`),
        date_of_approve: new Date()
      })

      batch.commit().then(() => loadApprove() ).catch( error => {
        notification.error({
          message: 'Error',
          description: 'Se presento un error al tratar de aprobar el archivo',
          placement:  'topRight'
        })  
      });

    }
  };

  /**
   * Metodo que permite eliminar un usuario aprobador
   */
  const removeApprove = () => {

    setApprove({ loading: true, source: approve.source });
    file.reference.ref.get().then( fields => {
      
      const data = fields?.data()?.approve?.filter( approve => approve === auth?.user?.email );
      setApprove({ loading: data?.length > 0 ? true : false, source: approve.source });
      if (data?.length <= 0) return;

      const uid = firebase.auth().currentUser.uid;
      const batch = firestore.batch();

      const log_audit = firestore.collection('audit').doc();
      const approves = approve.source.map(data => data.email);

      approves.splice(approves.indexOf(auth?.user?.email), 1);

      const before = file.fields;
      const after = { ...file.fields, approve: approves  };

      batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firestore.FieldValue.serverTimestamp() });
      batch.set(log_audit.collection('before').doc(), before);
      batch.set(log_audit.collection('after').doc(), after);
      batch.update(file.reference.ref, { approve: firestore.FieldValue.arrayRemove( data[0] ) });
      batch.delete(file.reference.ref.collection('approve').doc( auth?.user?.email ));

      batch.commit().then(() => loadApprove() ).catch( error => {
        setApprove({ loading: false, source: approve.source });
        notification.error({
          message: 'Error',
          description: 'Se presento un error al tratar de desaprobar el archivo',
          placement:  'topRight'
        });
      });

    });

  };

  /**
   * Metodo que permite eliminar una referencia
   * @param {int} index 
   */
  const deleteReference = index => {
    file.reference.ref.get().then( doc => {

      doc.ref.collection('references').where( 'name', '==', index?.name ).where( 'value', '==', index?.value ).get().then( query => {
        if ( !query.empty ) {

          const uid = firebase.auth().currentUser.uid;
          const batch = firestore.batch();

          query.docs.forEach( docRef => {

            const log_audit = firestore.collection('audit').doc();
            const refs = references.source.map(reference => {
              return {
                name: reference.index.name,
                value: reference.index.value
              }
            });

            refs.splice(refs.indexOf({ name: index?.name, value: index?.value }), 1);

            const before = { ...doc.data() };
            const after = { ...doc.data(), references: refs  };

            batch.set(log_audit, { uid, eventType: `${ DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`, eventDate: firestore.FieldValue.serverTimestamp() });
            batch.set(log_audit.collection('before').doc(), before);
            batch.set(log_audit.collection('after').doc(), after);
            batch.delete(docRef.ref);
            batch.update(doc.ref, { references: firestore.FieldValue.arrayRemove({ name: index?.name, value: index?.value }) });

            batch.commit().then(() => {
              loadReferences();
            }).catch( error => {
              notification.error({
                message: 'Error',
                description: 'Se presento un error al tratar de eliminar la referencia',
                placement:  'topRight'
              })
            });

          });

        } else loadReferences();
      });

    });
  };

  /**
   * Metodo que se ejecuta cuando termina de previsualizar el archivo PDF y establece el número de pagina total
   * @param {object} param0 
   */
  const onDocumentLoadSuccess = ({ numPages }) => setPreview({ ...preview, numPages });


  const downloadFile = ({ url, name }) => dispatch( filesActions.download( url, name ) );


  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => { form.resetFields(); }, [form]);
  
  // Ejecuta la funcion cada vez que el estado de referencia cambia
  useEffect(() => {
    if (file?.isLoad && file?.visible && file?.reference) {
      file.reference.ref.get().then( doc => {
        
        const { set, ...rest } = file;
        const fields = doc.data();

        set({ ...rest, fields, isLoad: false });
        setXml({ loading: fields?.xml ? true : false, source: [] }); 

        if (!fields?.xml) return;
        fields.xml.get().then( file => {
          setXml({
            loading: false,
            source: file.exists ? [{
              key: file.id,
              name: file.data().name,
              url: file.data().url,
              date: file.data().date_of_upload.toDate().toLocaleDateString('es-CO', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
            }] : []
          });
        });

      });
    }
  }, [file]);

  /**
   * Metodo que rederiza el componente
   */
  return file?.visible ? (
    <Drawer
      forceRender
      title = { `Información de Archivo` }
      width = { 780 }
      placement = "left"
      onClose = { onCloseFile }
      maskClosable = { false }
      visible = { file.visible }
      style = {{ height: 'calc(100% - 55px)', overflow: 'auto', paddingBottom: 53 }}
      destroyOnClose = { true } >

      <Row>
        <Col span = {24} >
          <DescriptionItem title = "Nombre del archivo" content = { file.fields?.name } />
        </Col>
      </Row>
      <Row>
        <Col span = {24} >
          <DescriptionItem
            title = "Fecha de subida"
            content = { file.fields?.date_of_upload?.toDate().toLocaleDateString('es-CO', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
          />
        </Col>
      </Row>
      <Row>
        <Col span = {6} >
          <Button type = "link" onClick = { () => downloadFile({ url: file.fields.url, name: file.fields.name }) } >
            <DownloadOutlined style = {{ marginRight: 8 }} />
            Descargar archivo
          </Button>
        </Col>

        { user.roles.documents?.delete && !file.fields?.accounting && !isClosing.data ?
            <Col span = {6} >
              <Button
                type = "link"
                style = {{ color: '#F5222D' }}
                onClick = { () => {
                  Modal.confirm({
                    title: `Eliminar archivo ${ file.fields?.name  }`,
                    content: 'Esta seguro de eliminar el archivo seleccionado?',
                    okText: 'Eliminar',
                    okType: 'danger',
                    cancelText: 'Cancelar',
                    onOk: deleteFile
                  })
                }}
              >
                <DeleteOutlined style = {{ marginRight: 8 }} />
                Eliminar archivo
              </Button>
            </Col> : null }

        { file.fields?.type === "application/pdf" ?
            <Col span = {6} >
              <Button
                type = "link"
                style = {{ color: '#FAAD14' }}
                onClick = { () => setPreview({ ...preview, visible: true }) }
              > <EyeOutlined style = {{ marginRight: 8 }} /> Previsualizar </Button>
            </Col> : null }

      </Row>

      <Divider />
      
      <Tabs type = "card" onChange = { onChangeTab } >
        <Tabs.TabPane tab = "XML" key = "1" >
          
          <Table
            bordered
            size = 'middle'
            loading = { xml.loading }
            locale = {{
              emptyText: user.roles.documents?.read && !isClosing.data ?
                <XMLUpload
                  key = "XML"
                  reference = { file.reference }
                  onXML = { loadXML }
                /> : null
            }}
            columns = {[
              { title: 'Nombre del archivo', dataIndex: 'name', key: 'name' },
              { title: 'Fecha de subida', dataIndex: 'date', key: 'date' },
              { title: 'Acciones', key: 'actions', render: ( text, record ) => (
                <span>
                  <Button type = "link" onClick = { () => downloadFile({ url: record?.url, name: record?.name }) } > Descargar </Button>
                  { user.roles.documents?.delete && !isClosing.data ?
                    <>
                      <Divider type = "vertical" />
                      <Popconfirm
                        title = "Esta seguro de eliminar el archivo？"
                        okText = "Eliminar"
                        cancelText = "Cancelar"
                        onConfirm = { deleteXML }
                      >
                        <Button type = "link" > Eliminar </Button>
                      </Popconfirm>
                    </> : null }
                </span>
              )}
            ]}
            dataSource = { xml.source }
            pagination = {{ pageSize: 4 }}
          />

        </Tabs.TabPane>
        <Tabs.TabPane tab = "Contabilización" key = "2" >
        
          <Table
            bordered
            size = 'middle'
            loading = { accounting.loading }
            locale = {{
              emptyText: user.roles.documents?.accounting && !isClosing.data ?
                <AccountingUpload
                  key = "accounting"
                  reference = { file.reference }
                  onAccounting = { loadAccounting }
                /> : null
            }}
            columns = {[
              { title: 'Nombre del archivo', dataIndex: 'name', key: 'name' },
              { title: 'Fecha contable', dataIndex: 'date', key: 'date' },
              { title: 'Acciones', key: 'actions', render: ( text, record ) => (
                <span>
                  <Button type = "link" onClick = { () => downloadFile({ url: record?.url, name: record?.name }) } > Descargar </Button>
                  { user.roles.documents?.accounting && !isClosing.data ?
                    <>
                      <Divider type = "vertical" />
                      <Popconfirm
                        title = "Esta seguro de reversar la contabilización？"
                        okText = "Reversar"
                        cancelText = "Cancelar"
                        onConfirm = { accountingThrowBack }
                      >
                        <Button type = "link" > Reversar </Button>
                      </Popconfirm>
                    </> : null }
                </span>
              )}
            ]}
            dataSource = { accounting.source }
            pagination = {{ pageSize: 4 }}
          />
        
        </Tabs.TabPane>
        <Tabs.TabPane tab = "Referencias" key = "3" >
          
          <Button
            loading = { references.loading }
            onClick = { openDrawerReference }
            type = "primary"
            style = {{ marginBottom: 16 }} >
            Agregar referencia
          </Button>

          <Table
            bordered
            size = 'middle'
            loading = { references.loading }
            locale = {{ emptyText: 'No se han agregado referencias' }}
            columns = {[
              { title: 'Referencia', dataIndex: 'ref', key: 'ref' },
              { title: 'Valor', dataIndex: 'value', key: 'value' },
              { title: 'Acciones', key: 'actions', render: ( text, record ) => (
                <span>
                  { user.roles.documents?.read && !isClosing.data ?
                      <Popconfirm
                        title = "Esta seguro de eliminar la referencia？"
                        okText = "Eliminar"
                        cancelText = "Cancelar"
                        onConfirm = { () => deleteReference( record?.index ) }
                      >
                        <Button type = "link" > Eliminar </Button>
                      </Popconfirm> : null }
                </span>
              )}
            ]}
            dataSource = { references.source }
            pagination = {{ pageSize: 4 }}
          />

        </Tabs.TabPane>
        <Tabs.TabPane tab = "Archivos vinculados" key = "4" >
        
          { !isClosing.data ?
              <LinkUpload
                key = "linking"
                reference = { file.reference }
                loading = { links.loading }
                onLinking = { loadLinksFiles }
                onPreview = { () => setLinks({ loading: true, source: links.source }) }
              /> : null }

          <Table
            bordered
            size = 'middle'
            loading = { links.loading }
            locale = {{ emptyText: 'No se han vinculado archivos' }}
            columns = {[
              { title: 'Nombre del archivo', dataIndex: 'name', key: 'name' },
              { title: 'Fecha de subida', dataIndex: 'date', key: 'date' },
              { title: 'Acciones', key: 'actions', render: ( text, record ) => (
                <span>
                  <Button type = "link" onClick = { () => downloadFile({ url: record?.url, name: record?.name }) } > Descargar </Button>
                  { user.roles.documents?.delete && !isClosing.data && !file.fields?.accounting ?
                    <>
                      <Divider type = "vertical" />
                      <Popconfirm
                        title = "Esta seguro de eliminar el archivo？"
                        okText = "Eliminar"
                        cancelText = "Cancelar"
                        onConfirm = { () => deleteLinkFile( record?.ref ) }
                      >
                        <Button type = "link" > Eliminar </Button>
                      </Popconfirm>
                    </> : null }
                </span>
              )}
            ]}
            dataSource = { links.source }
            pagination = {{ pageSize: 4 }}
          />
        
        </Tabs.TabPane>
        <Tabs.TabPane tab = "Aprobación de archivos" key = "5" >
          
          { auth ?
              <Button
                loading = { approve?.loading }
                onClick = { approve.source?.filter( user => user?.email === auth?.user?.email )?.length <= 0 ? saveApprove : removeApprove }
                type = "primary"
                style = {{ marginBottom: 16 }}
              >
                { approve.source?.filter( user => user?.email === auth?.user?.email ).length <= 0
                    ? 'Aprobar archivo'
                    : 'Desaprobar archivo' }
              </Button> : null }

          <Table
            bordered
            size = 'middle'
            loading = { approve.loading }
            locale = {{ emptyText: 'Este archivo no ha sido aprobado por ningún usuario' }}
            columns = {[
              { title: 'Usuario', dataIndex: 'name', key: 'name' },
              { title: 'Email', dataIndex: 'email', key: 'email' },
              { title: 'Fecha de aprobación', dataIndex: 'date', key: 'date' },
            ]}
            dataSource = { approve.source }
            pagination = {{ pageSize: 4 }}
          />
        </Tabs.TabPane>
      </Tabs>

      <Drawer
        forceRender
        title = 'Nueva referencia'
        width = { 320 }
        placement = "left"
        onClose = { closeDrawerReference }
        maskClosable = { false }
        visible = { references.visible }
        style = {{ height: 'calc(100% - 55px)', overflow: 'auto', paddingBottom: 53 }}
        destroyOnClose = { true } >

        { read?.type === REFERENCES.READ.READED_REFERENCE ?
            <Form form = { form } layout = "vertical" onFinish = { saveReference } >
              <Form.Item name = "reference" label = "Referencia"
                rules = {[{ required: true, message: 'La referencia es requerido' }]} >
                <Select placeholder = "Seleccione una referencia" onChange = { handleSelectChangeReference }
                  rules = {[{ required: true, message: 'La referencia es requerido' }]} >
                  {
                    read?.reference?.map( ref => <Select.Option key = { ref?.id } value = { ref?.id } > { ref?.name } </Select.Option> )
                  }
                </Select>
              </Form.Item>
              <Form.Item name = "value" label = "Valor de referencia"
                rules = {[
                  { required: true, message: 'El valor de referencia es requerido' },
                  { type: selected.type, message: selected.message }
                ]} >
                { selected.component ? selected.component : <Input style = {{ width: '100%' }} /> }
              </Form.Item>
              <Form.Item>
                <Button type = "primary" onClick = { () => form.submit() } >
                  Agregar
                </Button>
              </Form.Item>
            </Form> : null }

      </Drawer>

      { file.fields?.type === "application/pdf" ? 
          <Drawer
            forceRender
            title = { `${ file.fields?.name || '' } - Pagina ${ preview.pageNumber } de ${ preview.numPages }`  }
            width = '100%'
            placement = "left"
            onClose = { () => setPreview({ ...preview,  visible: false, numPages: 1, pageNumber: 1, rotate: 0, scale: 1 }) }
            maskClosable = { false }
            visible = { preview.visible }
            style = {{ height: 'calc(100% - 55px)', overflow: 'auto', paddingBottom: 53 }}
            destroyOnClose = { true } >

<div style={{ height: '750px' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={file.fields?.url}
                    plugins={[pageNavigationPluginInstance, zoomPluginInstance, rotatePluginInstance]}
                />
            </Worker>

        </div>

        <div style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'left',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
            }}>
                <Row>
                    <Col span={12}>
                        <Button
                            style={{ marginRight: 8 }}
                            onClick={jumpToPreviousPage}
                        >
                            <LeftCircleTwoTone /> Página anterior
                        </Button>

                        <Button
                            style={{ marginRight: 8 }}
                            onClick={jumpToNextPage}
                        >
                            Siguiente página <RightCircleTwoTone />
                        </Button>

        

                        <div style={{ display: 'inline-block', marginLeft: 8 }}>
                            <CurrentPageLabel /> / <NumberOfPages />
                        </div>
                    </Col>
                    <Col span={12}>
                        <Slider
                            min={1}
                            max={5}
                            step={0.1}
                            marks={{ 1: 'Zoom:' }}
                            onChange={value => zoomTo(value)}  // Usamos zoomTo para cambiar el nivel de zoom
                        />
                    </Col>
                </Row>
            </div>

          </Drawer> : null }

    </Drawer>
  ) : null;
};