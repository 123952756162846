/**
 * Reductor de REDUX para controlar los estados de los componentes de carga de archivos
 */

import { UPLOAD } from '../../application/types';
import { Map } from 'immutable';

/**
 * Exporta el reductor upload
 * @param {object} state 
 * @param {object} action 
 */
export const upload = ( state = Map({ type: UPLOAD.UPLOAD }), action ) => {

  switch ( action.type ) {

    case UPLOAD.UPLOAD:
      return state.set('upload', {
        type: action.type
      });
    
    case UPLOAD.UPLOADING:
      return state.set('upload', {
        type: action.type
      });
  
    case UPLOAD.UPLOADED:
      return state.set('upload', {
        type: action.type,
        data: action.data
      });

    case UPLOAD.ERROR:
      return state.set('upload', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}