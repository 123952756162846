/**
 * Reductor de REDUX para controlar los estados del componente de documentos
 */

import { DOCUMENTS } from '../../application/types';
import { Map } from 'immutable';

/**
 * Exporta el reductor documents
 * @param {object} state 
 * @param {object} action 
 */
export const documents = ( state = Map({ type: DOCUMENTS.READ.READ_DOCUMENTS }), action ) => {

  switch ( action.type ) {

    case DOCUMENTS.CREATE.CREATE_DOCUMENTS:
      return state.set('create', {
        type: action.type
      });
    
    case DOCUMENTS.CREATE.CREATING_DOCUMENTS:
      return state.set('create', {
        type: action.type,
      });
  
    case DOCUMENTS.CREATE.CREATED_DOCUMENTS:
      return state.set('create', {
        type: action.type,
        data: action.data
      });

    case DOCUMENTS.CREATE.ERROR:
      return state.set('create', {
        type: action.type,
        code: action.code,
        message: action.message
      });
    
    case DOCUMENTS.READ.READ_DOCUMENTS:
      return state.set('read', {
        type: action.type
      });
    
    case DOCUMENTS.READ.READING_DOCUMENTS:
      return state.set('read', {
        type: action.type,
      });
  
    case DOCUMENTS.READ.READED_DOCUMENTS:
      return state.set('read', {
        type: action.type,
        data: action.data
      });

    case DOCUMENTS.READ.ERROR:
      return state.set('read', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case DOCUMENTS.UPDATE.UPDATE_DOCUMENTS:
      return state.set('update', {
        type: action.type
      });
    
    case DOCUMENTS.UPDATE.UPDATING_DOCUMENTS:
      return state.set('update', {
        type: action.type,
      });
  
    case DOCUMENTS.UPDATE.UPDATED_DOCUMENTS:
      return state.set('update', {
        type: action.type,
        data: action.data
      });

    case DOCUMENTS.UPDATE.ERROR:
      return state.set('update', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case DOCUMENTS.DELETE.DELETE_DOCUMENTS:
      return state.set('delete', {
        type: action.type
      });
    
    case DOCUMENTS.DELETE.DELETING_DOCUMENTS:
      return state.set('delete', {
        type: action.type,
      });
  
    case DOCUMENTS.DELETE.DELETED_DOCUMENTS:
      return state.set('delete', {
        type: action.type,
        data: action.data
      });

    case DOCUMENTS.DELETE.ERROR:
      return state.set('delete', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case DOCUMENTS.CLOSE.CLOSE_DOCUMENTS:
      return state.set('close', {
        type: action.type
      });
    
    case DOCUMENTS.CLOSE.CLOSING_DOCUMENTS:
      return state.set('close', {
        type: action.type,
      });
  
    case DOCUMENTS.CLOSE.CLOSED_DOCUMENTS:
      return state.set('close', {
        type: action.type,
        data: action.data
      });

    case DOCUMENTS.CLOSE.ERROR:
      return state.set('close', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case DOCUMENTS.ACCOUNTING.ACCOUNTING_THROW_BACK:
      return state.set('accounting', {
        type: action.type
      });
    
    case DOCUMENTS.ACCOUNTING.ERROR:
      return state.set('accounting', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case DOCUMENTS.CLONE.CLONE_DOCUMENTS:
      return state.set('clone', {
        type: action.type
      });
    
    case DOCUMENTS.CLONE.CLONING_DOCUMENTS:
      return state.set('clone', {
        type: action.type
      });
    
    case DOCUMENTS.CLONE.CLONED_DOCUMENTS:
      return state.set('clone', {
        type: action.type,
        ref: action.ref
      });
    
    case DOCUMENTS.CLONE.ERROR:
      return state.set('clone', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    case DOCUMENTS.SEARCHER.SEARCH_DOCUMENTS:
      return state.set('searcher', {
        type: action.type
      });

    case DOCUMENTS.SEARCHER.SEARCHING_DOCUMENTS:
      return state.set('searcher', {
        type: action.type
      });

    case DOCUMENTS.SEARCHER.FOUND_DOCUMENTS:
      return state.set('searcher', {
        type: action.type,
        documents: action.documents
      });
    
    case DOCUMENTS.SEARCHER.NOT_FOUND_DOCUMENTS:
      return state.set('searcher', {
        type: action.type
      });

    case DOCUMENTS.SEARCHER.ERROR:
      return state.set('searcher', {
        type: action.type,
        code: action.code,
        message: action.message
      });

    default:
      return state;

  }

}