/**
 * Componente Permissions que permite llevar acabo todas las operaciones sobre los permisos por carpeta
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Select, Divider, Checkbox, Card, Spin, notification } from 'antd';

import { documents as documentsActions } from '../../actions/database/documents';
import { useDocuments } from './context';

/**
 * Estilos en linea del componente
 */
const gridStyle = { width: '30%', textAlign: 'center' };

/**
 * Exporta el componente Permissions
 */
export const Permissions = () => {

  // Funcion que permite ejecutar todas las acciones del estado de redux
  const dispatch = useDispatch();

  // Manejo de los estados del componente
  const [select, setSelect] = useState({ value: undefined, loading: false });

  // Hook para el control de los formularios
  const [form] = Form.useForm();

  const { folders } = useDocuments();
  const { roles, permissions } = folders.current.user;

  /**
   * Metodo que se ejcuta cuado cambia el selector de usuarios
   * @param {string} value 
   */
  const onSelectChange = value => {

    // form.resetFields();
    setSelect({ value, loading: true });
    
    permissions.folder.reference.path.collection('users').doc( value ).get().then( user => {
      if ( user.exists ) {

        const data = user.data();
        const { documents, folders } = data?.permissions;

        if ( documents && folders ) {
          form.setFields([
            { name: "user", value },
            { name: "folder_read", value: folders.read ? folders.read : false },
            { name: "folder_create", value: folders.create ? folders.create : false },
            { name: "folder_update", value: folders.update ? folders.update : false },
            { name: "folder_delete", value: folders.delete ? folders.delete : false },
            { name: "folder_closing", value: folders.closing ? folders.closing : false },
            { name: "file_read", value: documents.read ? documents.read : false },
            { name: "file_delete", value: documents.delete ? documents.delete : false },
            { name: "file_accounting", value: documents.accounting ? documents.accounting : false }
          ]);
        } else {
          form.setFields([
            { name: "user", value },
            { name: "folder_read", value: false },
            { name: "folder_create", value: false },
            { name: "folder_update", value: false },
            { name: "folder_delete", value: false },
            { name: "folder_closing", value: false },
            { name: "file_read", value: false },
            { name: "file_delete", value: false },
            { name: "file_accounting", value: false }
          ]);
        }
        
      } else {
        form.setFields([
          { name: "user", value },
          { name: "folder_read", value: false },
          { name: "folder_create", value: false },
          { name: "folder_update", value: false },
          { name: "folder_delete", value: false },
          { name: "folder_closing", value: false },
          { name: "file_read", value: false },
          { name: "file_delete", value: false },
          { name: "file_accounting", value: false }
        ]);
      } setSelect({ value, loading: false });
    });

  };

  /**
   * Metodo que permite asignar los permisos a un usuario
   */
  const setPermissions = values => {
    setSelect({ value: select.value, loading: true });
    dispatch( documentsActions.setPermissions({
      roles: roles.folders,
      reference: permissions.folder.reference.path,
      permissions: {
        documents: {
          accounting: values.file_accounting,
          delete: values.file_delete,
          read: values.file_read
        },
        folders: {
          closing: values.folder_closing,
          create: values.folder_create,
          delete: values.folder_delete,
          read: values.folder_read,
          update: values.folder_update
        }
      },
      uid: values.user
    })).then( () => {

      form.resetFields();
      setSelect({ value: values.user, loading: false });
      notification.success({
        message: 'Asignación de permisos',
        description: 'La asignación de permisos se realizó correctamente',
        placement: 'topLeft'
      });

    }).catch( () => {

      form.resetFields();
      setSelect({ value: values.user, loading: false });
      notification.error({
        message: 'Asignación de permisos',
        description: 'Se presento un error al tratar de asignar los permisos',
        placement: 'topLeft'
      });

    });
  };

  /**
   * Metodo que se ejcuta cuando se cierre la ventana emergente de permisos
   */
  const onCloseDrawer = () => {
    form.resetFields();
    permissions.set({ visible: false, users: permissions.users });
  };

  // Ejecuta la funcion una vez termina de renderizar el componente
  useEffect(() => { form.resetFields(); }, [form]);

  /**
   * Metodo que rederiza el componente
   */
  return permissions.visible ? (
    <Drawer
      forceRender
      title = { `Editar permisos para la carpeta ${ permissions.folder?.name }` }
      width = { 720 }
      placement = "left"
      onClose = { onCloseDrawer }
      maskClosable = { false }
      visible = { permissions.visible }
      style = {{ height: 'calc(100% - 55px)', overflow: 'auto', paddingBottom: 53 }} >
      <Form form = { form } layout = "vertical" onFinish = { setPermissions } >
        <Form.Item name = "user" label = "Usuario:"
          rules = {[{ required: true, message: 'Debe seleccionar un usuario' }]} >
          <Row>
            <Col span = { 24 } >
              <Select placeholder = "Seleccione un usuario" onChange = { onSelectChange } >
                { permissions.users.filter( user => user.isAdmin !== true ).map( ( user, index ) => (
                    <Select.Option key = { index } value = { user.reference.id } > { user.email } </Select.Option>      
                )) }
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Divider />
        <Spin spinning = { select.loading } >
          <Card title = "Permisos a carpetas" bordered = { false } >
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "folder_read" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Leer </Checkbox>
              </Form.Item>
            </Card.Grid>
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "folder_create" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Crear </Checkbox>
              </Form.Item>
            </Card.Grid>
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "folder_update" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Editar </Checkbox>
              </Form.Item>
            </Card.Grid>
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "folder_delete" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Eliminar </Checkbox>
              </Form.Item>
            </Card.Grid>
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "folder_closing" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Cerrar </Checkbox>
              </Form.Item>
            </Card.Grid>
          </Card>

          <Card title = "Permisos a archivos" bordered = { false } >
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "file_read" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Leer </Checkbox>
              </Form.Item>
            </Card.Grid>
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "file_delete" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Eliminar </Checkbox>
              </Form.Item>
            </Card.Grid>
            <Card.Grid style = { gridStyle } >
              <Form.Item name = "file_accounting" valuePropName = "checked" initialValue = { false } >
                <Checkbox> Contabilizar </Checkbox>
              </Form.Item>
            </Card.Grid>
          </Card>
        </Spin>

      </Form>
      <div style = {{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }} >
        <Button style = {{ marginRight: 8 }} loading = { select.loading } onClick = { onCloseDrawer } > Cancelar </Button>
        <Button type = "primary" htmlType = "submit" onClick = { () => form.submit() } loading = { select.loading } > Editar permisos </Button>
      </div>
    </Drawer>
  ) : false;

};