import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select, Modal, Form, TreeSelect, notification } from 'antd';

import { folders as foldersActions } from '../../actions/database/folders';
import { documents as documentsActions } from '../../actions/database/documents';
import { useDocuments } from './context';

export const useModalTemplate = () => {

    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();
    // Manejo de los estados del componente
    const [templates, setTemplates] = useState({ visible: false, loading: false, source: [] });
    
    const loadTemplates = ({ userRoles }) => {
        dispatch( foldersActions.templates.read({ roles: userRoles.folders }) ).then( templates => {
            setTemplates({
                visible: true,
                loading: false,
                source: templates.template.data() ? templates.template.data().templates.map( ( tpl, index ) => ({
                    key: index,
                    name: tpl.name,
                    value: index,
                    ref: templates.template
                })) : []
            })
        }).catch( error => {
            notification.error({
                message: 'Error',
                description: "Se presento un error al tratar de cargar las plantillas",
                placement: 'topLeft'
            });
        });
    };
    
    return {
        templates: {
            ...templates,
            set: setTemplates,
            load: loadTemplates
        }
    };
};

export const ModalTemplate = () => {
            
    // Funcion que permite ejecutar todas las acciones del estado de redux
    const dispatch = useDispatch();
    // Hook para el control de los formularios
    const [form] = Form.useForm();
    // Manejo de los estados del componente
    const [treeData, setTreeData] = useState({ value: [], source: [], loading: false });

    const { folders, templates } = useDocuments();
    const { current } = folders;

    /**
     * Metodo que permite cargar la estructura de carpetas de una plantilla
     */
    const loadFoldersTemplate = async ({ doc }) => {
        try {

            const fields = await doc.get();
            setTreeData({ source: [], value: [], loading: true });

            if ( fields.exists ) {

                const folders = [];
                const template = fields.data()?.folders || [];

                for ( const folder of template ) {

                    const index = folders.push({
                        key: `${ folder.reference.path.path }/${ folder.reference.id }`,
                        title: folder.name,
                        value: `${ folder.reference.path.path }/${ folder.reference.id }`,
                        children: []
                    });

                    const subfolder = await doc.collection( folder.reference.id ).get() || [];
                    
                    for ( const doc of subfolder.docs ) {
                        const subFolders = await loadFoldersTemplate({ doc: doc.ref }) || [];
                        subFolders.forEach( data => folders[ index - 1 ].children.push( data ) );
                    }

                } return folders;

            } else return [];

        } catch( error ) { console.error(error); }
    };

    // Funcion que construye los nodos de seleccion de carpeta
    const buildTreeNode = nodes => nodes?.map(({ children, ...props }) => children?.length > 0
        ? <TreeSelect.TreeNode { ...props } > { buildTreeNode(children) } </TreeSelect.TreeNode>
        : <TreeSelect.TreeNode { ...props } />
    );

    /**
     * Metodo que se ejecuta cuando se cambia la plantilla
     * @param {object} item 
     */
    const onTemplateSelectChange = async item => {
        const template = templates.source[ item ];
        const source = buildTreeNode(await loadFoldersTemplate({ doc: template.ref.ref.collection( template.name ).doc('documents') }));
        setTreeData({
            source,
            value: [],
            loading: false
        });
    };

    /**
     * Metodo que permite clonar una plantilla de carpetas
     */
    const cloneFolder = values => {
        templates.set({ source: templates.source, visible: true, loading: true });
        dispatch(
            documentsActions.clone({
                roles: current.user.roles.folders,
                reference: current.reference.data,
                folders: values.cloneFolders
            })
        ).then( data => {
            form.resetFields();
            templates.set({ source: [], visible: false, loading: false });
            folders.set({ ...folders.data, isLoad: true });
        }).catch( error => {
            notification.error({
                message: 'Error',
                description: 'Se presento un error al tratar de clonar la carpeta',
                placement:  'topRight'
            });
        });
    };
    
    return templates.visible ? (
        <Modal
            forceRender
            visible = { templates.visible }
            confirmLoading = { templates.loading }
            title = "Clonar carpeta"
            okText = "Clonar"
            cancelText = "Cancelar"
            maskClosable = { false }
            closable = { false }
            onOk = { () => form.submit() }
            onCancel = { () => {
                form.resetFields();
                templates.set({ source: [], visible: false, loading: false });
                setTreeData({ source: [], value: [], loading: false });
            }} >
            <Form layout = "vertical" form = { form } onFinish = { cloneFolder } >
                <Form.Item name = "template" label = "Plantilla"
                    rules = {[{ required: true, message: 'Debe seleccionar una plantilla' }]} >
                    <Select placeholder = "Seleccione una plantilla" onSelect = { onTemplateSelectChange } >
                        { templates.source.map( template => (
                            <Select.Option key = { template.key } value = { template.value } > { template.name } </Select.Option>
                        )) }
                    </Select>
                </Form.Item>
                <Form.Item name = "cloneFolders" label = "Carpetas"
                    validateStatus = { treeData.loading ? "validating" : "success" } 
                    help = { treeData.loading ? "Cargando las carpetas..." : "Carpetas cargadas" }
                    rules = {[{ required: true, message: 'Debe seleccionar las carpetas a clonar' }]} >
                    <TreeSelect
                        disabled = { treeData.loading }
                        onSelect = { value => setTreeData({ value, source: treeData.source, loading: treeData.loading }) }
                        treeCheckable = { true }
                        showCheckedStrategy = { TreeSelect.SHOW_PARENT }
                        placeholder = 'Seleccione las carpetas' >
                        { treeData.source }
                    </TreeSelect>
                </Form.Item>
            </Form>
        </Modal>
    ) : false
};