/**
 * Importación de las dependencias del proyecto
 */
import * as TYPES from '../../../application/types';

/**
 * Exporta un objeto con el mapeo de los eventos
 * generados desde el aplicativo y que afetcan a la base de datos.
 */
export default {

    [TYPES.CUSTOMERS.CREATE.CREATE_CUSTOMERS]: "Creación de cliente",
    [TYPES.CUSTOMERS.UPDATE.UPDATE_CUSTOMERS]: "Actualización de cliente",
    [TYPES.CUSTOMERS.DELETE.DELETE_CUSTOMERS]: "Eliminación de cliente",

    [TYPES.DOCUMENTS.CREATE.CREATE_DOCUMENTS]: "Creación de carpeta",
    [TYPES.DOCUMENTS.UPDATE.UPDATE_DOCUMENTS]: "Actualización de carpeta",
    [TYPES.DOCUMENTS.DELETE.DELETE_DOCUMENTS]: "Eliminación de carpeta",

    [TYPES.FOLDERS.TEMPLATES.CREATE.CREATE_TEMPLATE]: "Creación de plantilla",
    [TYPES.FOLDERS.TEMPLATES.UPDATE.UPDATE_TEMPLATE]: "Actualización de plantilla",
    [TYPES.FOLDERS.TEMPLATES.DELETE.DELETE_TEMPLATE]: "Eliminación de plantilla",

    [`${ TYPES.DOCUMENTS.CREATE.CREATE_DOCUMENTS }_TEMPLATES`]: "Creación de carpeta en plantilla",
    [`${ TYPES.DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_TEMPLATES`]: "Actualización de carpeta en plantilla",
    [`${ TYPES.DOCUMENTS.DELETE.DELETE_DOCUMENTS }_TEMPLATES`]: "Eliminación de carpeta en plantilla",

    [`${ TYPES.DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_PERMISSIONS`]: "Actualización de permisos",

    [`${ TYPES.DOCUMENTS.CREATE.CREATE_DOCUMENTS }_FILES`]: "Creación de archivo",
    [`${ TYPES.DOCUMENTS.UPDATE.UPDATE_DOCUMENTS }_FILES`]: "Actualización de archivo",
    [`${ TYPES.DOCUMENTS.DELETE.DELETE_DOCUMENTS }_FILES`]: "Eliminación de archivo",

    [TYPES.REFERENCES.CREATE.CREATE_REFERENCE]: "Creación de referencia",
    [TYPES.REFERENCES.UPDATE.UPDATE_REFERENCE]: "Actualización de referencia",
    [TYPES.REFERENCES.DELETE.DELETE_REFERENCE]: "Eliminación de referencia",

    [TYPES.USERS.CREATE.CREATE_USERS]: "Creación de usuario",
    [TYPES.USERS.UPDATE.UPDATE_USERS]: "Actualización de usuario",
    [TYPES.USERS.DELETE.DELETE_USERS]: "Eliminación de usuario",

};