/**
 * Componente que inicializa la aplicación
 */

import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';


import * as serviceWorker from './serviceWorker';
import { reducers } from './application/reducers';
import { settings } from './config/services';
import { Router } from './application/navigation';

/**
 * Inicializa y configura las instancias de firebase
 */
firebase.initializeApp( settings.firebase.config );
firebase.firestore();

/**
 * Crea un store que permite la comunicación entre las acciones y los reductores de REDUX
 */
const store = createStore(
  reducers,
  compose(
    applyMiddleware( thunk.withExtraArgument( getFirebase ) )
  )
);

const rrfProps = {
  firebase,
  config: settings.reactReduxFirebase.config,
  dispatch: store.dispatch,
  createFirestoreInstance
};


ReactDOM.render(
  // <React.StrictMode>
    <Provider store = { store } >
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router />
      </ReactReduxFirebaseProvider>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
